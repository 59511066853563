import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../providers/data.service';

@Component({
  selector: 'app-voa-info',
  templateUrl: './voa-info.component.html',
  styleUrls: ['./voa-info.component.scss'],
})
export class VoaInfoComponent implements OnInit {

  nameName = "";
  phoneName = "";
  sendContactFLAG = false;

  constructor(public _DataProvider: DataService, public _http: HttpClient) { }

  ngOnInit() { }

  closeModal() {
    this._DataProvider.mdoalvoaopen = false;
  }

  sendData() {
    this._http.post('https://webc.co.il/voaMail/', { name: this.nameName, phone: this.phoneName }).subscribe(respone => {
      console.log('respone: ', respone);
      this.sendContactFLAG = true;
    });

  }

}
