import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/providers/data.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit {

  id;
  lang;
  spot: any = {

    src: ''

  };


  bgImg = "";
  textData: any = "";
  src = "";
  choosenColor = "";
  exhibition;
  // spotType = "";
  autoPlay = false;
  exhibitionNname;
  playShow = false;
  btnEnter;
  currentTrack;
  // exhibitionID = '';
  exhibitionColor;
  activeBoard = false;
  activePlayer = false;

  showAudio = false;
  showText = false;
  workName: any = false;
  dis: any = false;
  year: any = false;
  moreInfo: any = false;
  credit: any = false;
  srcAudio = ''
  player: any;
  player_duration = '00:00';
  player_currentTime = '00:00';

  // params;

  // lang = '';
  constructor(
    // public navParams: NavParams,
    public _DataProvider: DataService,
    ) {
    // if (!this._DataProvider.getLocalStorage('lang')) {

    //   this._DataProvider.setLocalStorage('lang', "he");

    // }   

    // if(this.navParams.get('muzeum') && !this._DataProvider.getLocalStorage('muzeum')){

    //   this._DataProvider.setLocalStorage('muzeum',this.navParams.get('muzeum'));

    // }else{
    //   if(!this.navParams.get('muzeum') && !this._DataProvider.getLocalStorage('muzeum')){

    //   }
    // }


  }

  // PauseAudio() {
  //   return;
  //   document.getElementById('pauseBTN').click();
  // }

  initX = 0;
  currentX = 0;

  touchstart(ev) {
    this.initX = ev.changedTouches[0].clientX;
  }

  touchmove(ev) {
    this.currentX = ev.changedTouches[0].clientX;
  }

  touchend() {
    if (this._DataProvider.player_state == 'min') {
      if ((this.currentX - this.initX) > 50) {
        let el = document.getElementById('player_page');
        el.classList.add('slideout');
        this._DataProvider.current_spot.next(null);
      }
      this.initX = 0;
      this.currentX = 0;
    }

  }

  initTime = 0;
  knob_touchstart(ev) {
    this.initTime = this.player.currentTime;
    this.initX = ev.changedTouches[0].clientX;
  }

  knob_touchmove(ev) {
    let barWidth = document.getElementById('progress_bar').offsetWidth;
    this.currentX = ev.changedTouches[0].clientX;
    let gap = this.currentX - this.initX;
    let ahuz_gap = gap / barWidth;
    let time_add = ahuz_gap * this.player.duration;
    this.player.currentTime = this.initTime + time_add;
  }

  page;

  knob_keydown_start(ev, page) {
    console.log(ev);
    console.log(page);
    this.page = page;
    this.initTime = this.player.currentTime;
    this.initX = ev.clientX;

    this.boundMove = this.knob_onmove.bind(this);
    page.addEventListener('mousemove', this.boundMove);
    this.boundMouseUp = this.knob_mouseup.bind(this);
    page.addEventListener('mouseup', this.boundMouseUp);
  }

  knob_onmove(ev) {
    console.log(ev);
    
    let barWidth = document.getElementById('progress_bar').offsetWidth;
    this.currentX = ev.clientX;
    let gap = this.currentX - this.initX;
    let ahuz_gap = gap / barWidth;
    let time_add = ahuz_gap * this.player.duration;
    this.player.currentTime = this.initTime + time_add;
  }


  knob_mouseup(ev) {
      console.log("MOUSEUPUPUPUP");
      console.log(ev);
      this.page.removeEventListener('mousemove', this.boundMove);
      this.page.removeEventListener('mouseup', this.boundMouseUp);
  }

  boundMove() {}
  boundMouseUp() {}


  addzero(num) {
    if (num.toString().length < 2) {
      num = '0' + num;
    }
    return num;
  }

  timeConvert(seconds) {
    let min = Math.floor(seconds / 60);
    let sec = Math.floor(seconds % 60);
    return this.addzero(min) + ':' + this.addzero(sec);
  }

  timeupdate() {
    if (this.player.duration) {
      this.player_duration = this.timeConvert(this.player.duration);
      this.player_currentTime = this.timeConvert(this.player.currentTime);
    }
  }

  handleClick(action, ev) {
    ev.stopPropagation();
    console.log(this.player.duration);
    console.log(this.player.currentTime);
    console.log(this.player.playbackRate);

    if (action == 'play') {
      this.player.play();
    } else if (action == 'pause') {
      this.player.pause();
    } else if (action == 'backplayRate') {
      if (this.player.playbackRate < 2) {
        this.player.playbackRate += 0.25;
      } else {
        this.player.playbackRate = 0.25;
      }
    }
  }

  contentLoaded = false;
  ngOnInit() {


    // this._DataProvider.currentPage = 'spot'
    this._DataProvider.current_spot.subscribe((spot: any) => {
      console.log(this.player);
      if (spot) {
        // this.PauseAudio();
        console.log('44444444444 current_spot', spot);
        this.contentLoaded = false;
        // this.exhibitionID = this._DataProvider.getLocalStorage('exhibitionID');
        // this.spotType = this._DataProvider.getLocalStorage('spotType');
        this.id = spot.id;


        // console.log("ionViewDidLoad spot-page", this._DataProvider.params, this.spotType, this.id)
        // this._DataProvider.showPad = false;
        this.getSpotFunc(spot);
        this._DataProvider.setStatstic("go spot")

      }

    })


    // this._DataProvider.stop_audio.subscribe(stop => {
    //   if (stop) {
    //     this.PauseAudio();
    //   }
    // })



  }





  // changeState(state) {
  //   this._DataProvider.player_state = state;
  //   if (state == 'close') {
  //     // this.PauseAudio()
  //     this.handleClick('pause');
  //   }
  // }


  ionViewDidEnter() {
    this._DataProvider.currentPage = 'spot'

  }

  // ionViewCanLeave() {
  //   this.PauseAudio();
  // }

  checkText() {
    if (this.showText) {
      return true;
    } else {
      return false;
    }
  }

  checkAudio() {
    if (this.showAudio && this.srcAudio != '') {
      return true;
    } else {
      return false;
    }
  }

  checkdis() {
    if (this.dis) {
      return true;
    } else {
      return false;
    }
  }

  checkworkName() {
    if (this.workName) {
      return true;
    } else {
      return false;
    }
  }

  checkyear() {
    if (this.year) {
      return true;
    } else {
      return false;
    }
  }

  checkmoreInfo() {
    if (this.moreInfo) {
      return true;
    } else {
      return false;
    }
  }

  checkcredit() {
    if (this.credit) {
      return true;
    } else {
      return false;
    }
  }

  ionViewDidLoad() {


    // this.exhibitionID = this._DataProvider.getLocalStorage('exhibitionID');
    // this.spotType = this._DataProvider.getLocalStorage('spotType');
    // this.id = this._DataProvider.getLocalStorage('selectedSpotID');


    // console.log("ionViewDidLoad spot-page", this.exhibitionID, this.spotType, this.id)
    // this._DataProvider.showPad = false;
    // this.getSpotFunc(this.id);
  }// end of ionViewDidLoad


  defaultSize = 18;

  onTrackFinished(track: any) {
    console.log('Track finished', track)
    this._DataProvider.setStatstic("finish spot");
  }

  sizeText(direction) {
    console.log(direction, this.defaultSize);
    if (this.defaultSize >= 14 && this.defaultSize <= 33) {
      if (direction == 'plus') {
        if (this.defaultSize != 33) {
          this.defaultSize++;
        }
      } else {
        if (this.defaultSize != 14) {
          this.defaultSize--;
        }
      }

    }//
  }

  // goToCode(){
  //   this.navCtrl.setRoot(ExhibitionPage, {
  //     muzeum:this._DataProvider.Muzeum.urlCode,
  //   })
  // }   




  getDirection() {
    if (this._DataProvider.lang.getValue() == 'he') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

  digits = [" ", " ", " "];
  digitsIndex = 0;
  result;
  spotName = "";


  getSpotFunc(spot) {
    let self = this;

    // this._DataProvider.FBcol.collection('spots').doc(spotID).ref.get().then(function (doc) {
    // if (doc.exists) {
    // console.log("Document data:", doc.data());
    // const res: any = doc.data();
    // console.log('res', res);
    self.spot = spot;
    self.player = document.getElementById('player');
    self.player.load();

    var rep_regex2 = new RegExp('<pre>', "gi");
    spot.textData = spot.textData.replace(rep_regex2, "");
    var rep_regex3 = new RegExp('</pre>', "gi");
    spot.textData = spot.textData.replace(rep_regex3, "");



    self.textData = spot.textData;
    // self.lang = res.lang;
    // self._DataProvider.lang.next(spot.lang);
    self.srcAudio = spot.src;

   

    if (spot.hasOwnProperty('credit')) {
      if (spot.credit != '') {
        self.credit = spot.credit;
      } else {
        self.credit = false;
      }
    } else {
      self.credit = false;
    }




    if (spot.hasOwnProperty('workName')) {
      if (spot.workName != '') {
        self.workName = spot.workName;
      } else {
        self.workName = false;
      }
    } else {
      self.workName = false;
    }


    if (spot.hasOwnProperty('year')) {
      if (spot.year != '') {
        self.year = spot.year;
      } else {
        self.year = false;
      }
    } else {
      self.year = false;
    }


    if (spot.hasOwnProperty('dis')) {
      if (spot.dis != '') {
        self.dis = spot.dis;
      } else {
        self.dis = false;
      }
    } else {
      self.dis = false;
    }

    if (spot.hasOwnProperty('moreInfo')) {
      if (spot.moreInfo != '') {
        self.moreInfo = spot.moreInfo;
      } else {
        self.moreInfo = false;
      }
    } else {
      self.moreInfo = false;
    }




    if (spot.hasOwnProperty('showAudio')) {
      if (spot.showAudio) {
        self.showAudio = spot.showAudio;
      }

    } else {
      console.log('have no hasOwnProperty   showAudio');
      self.showAudio = true;
    }

    if (spot.showText) {
      self.showText = spot.showText;
    } else {
      self.showText = false;
    }

    self.activePlayer = true;


    

    console.log('self.spot:', self.spot);
    self.bgImg = self.spot.bgImg;
    self.src = self.spot.src;
    self.choosenColor = self.spot.choosenColor;
    // document.querySelector("meta[name=theme-color]").setAttribute("content", self.choosenColor);

    console.log(self.spot.src, self.spot.name);
    self.currentTrack = {
      src: self.spot.src,
      title: self.spot.name,
      preload: 'metadata' // tell the plugin to preload metadata such as duration for this track, set to 'none' to turn off
    };
    this.contentLoaded = true;


    //   } 
    //   else {
    //     console.log("No such document!");
    //   }
    // }).catch(function (error) {
    //   console.log("Error getting document:", error);
    // });

  }

  // getSpotFunc2(spotID) {

  //   if (this._DataProvider.spotsList) {
  //     let filteredSpot = this._DataProvider.spotsList.filter(element => element.id == spotID);
  //     console.log(filteredSpot);
  //     this.spot = filteredSpot[0];
  //     this.activePlayer = true;
  //     console.log('this.spot:', this.spot);
  //     this.bgImg = this.spot.bgImg;
  //     this.src = this.spot.src;
  //     this.choosenColor = this.spot.choosenColor;
  //     this.srcAudio = this.spot.src;







  //     if (this.spot.hasOwnProperty('workName')) {
  //       if (this.spot.workName != '') {
  //         this.workName = this.spot.workName;
  //       } else {
  //         this.workName = false;
  //       }
  //     } else {
  //       this.workName = false;
  //     }


  //     if (this.spot.hasOwnProperty('year')) {
  //       if (this.spot.year != '') {
  //         this.year = this.spot.year;
  //       } else {
  //         this.year = false;
  //       }
  //     } else {
  //       this.year = false;
  //     }


  //     if (this.spot.hasOwnProperty('dis')) {
  //       if (this.spot.dis != '') {
  //         this.dis = this.spot.dis;
  //       } else {
  //         this.dis = false;
  //       }
  //     } else {
  //       this.dis = false;
  //     }

  //     if (this.spot.hasOwnProperty('moreInfo')) {
  //       if (this.spot.moreInfo != '') {
  //         this.moreInfo = this.spot.moreInfo;
  //       } else {
  //         this.moreInfo = false;
  //       }
  //     } else {
  //       this.moreInfo = false;
  //     }




  //     if (this.spot.hasOwnProperty('showAudio')) {
  //       if (this.spot.showAudio) {
  //         this.showAudio = this.spot.showAudio;
  //       }

  //     } else {
  //       console.log('have no hasOwnProperty   showAudio');
  //       this.showAudio = true;
  //     }

  //     if (this.spot.showText) {
  //       this.textData = this.spot.textData;
  //       this.showText = this.spot.showText;
  //     } else {
  //       this.showText = false;
  //     }



  //     document.querySelector("meta[name=theme-color]").setAttribute("content", this.choosenColor);

  //     console.log(this.spot.src, this.spot.name);
  //     this.currentTrack = {
  //       src: this.spot.src,
  //       title: this.spot.name,
  //       preload: 'metadata' // tell the plugin to preload metadata such as duration for this track, set to 'none' to turn off
  //     };


  //   } else {


  //     let self = this;

  //     this._DataProvider.FBcol.collection('spots').doc(spotID).ref.get().then(function (doc) {
  //       if (doc.exists) {
  //         console.log("Document data:", doc.data());
  //         const res: any = doc.data();
  //         console.log('res', res);
  //         self.spot = res;
  //         self.textData = res.textData;
  //         // self.lang = res.lang;
  //         self._DataProvider.lang.next(res.lang);

  //         self.srcAudio = res.src;




  //         if (res.hasOwnProperty('credit')) {
  //           if (res.credit != '') {
  //             self.credit = res.credit;
  //           } else {
  //             self.credit = false;
  //           }
  //         } else {
  //           self.credit = false;
  //         }




  //         if (res.hasOwnProperty('workName')) {
  //           if (res.workName != '') {
  //             self.workName = res.workName;
  //           } else {
  //             self.workName = false;
  //           }
  //         } else {
  //           self.workName = false;
  //         }


  //         if (res.hasOwnProperty('year')) {
  //           if (res.year != '') {
  //             self.year = res.year;
  //           } else {
  //             self.year = false;
  //           }
  //         } else {
  //           self.year = false;
  //         }


  //         if (res.hasOwnProperty('dis')) {
  //           if (res.dis != '') {
  //             self.dis = res.dis;
  //           } else {
  //             self.dis = false;
  //           }
  //         } else {
  //           self.dis = false;
  //         }

  //         if (res.hasOwnProperty('moreInfo')) {
  //           if (res.moreInfo != '') {
  //             self.moreInfo = res.moreInfo;
  //           } else {
  //             self.moreInfo = false;
  //           }
  //         } else {
  //           self.moreInfo = false;
  //         }




  //         if (res.hasOwnProperty('showAudio')) {
  //           if (res.showAudio) {
  //             self.showAudio = res.showAudio;
  //           }

  //         } else {
  //           console.log('have no hasOwnProperty   showAudio');
  //           self.showAudio = true;
  //         }

  //         if (res.showText) {
  //           self.showText = res.showText;
  //         } else {
  //           self.showText = false;
  //         }

  //         self.activePlayer = true;

  //         console.log('self.spot:', self.spot);
  //         self.bgImg = self.spot.bgImg;
  //         self.src = self.spot.src;
  //         self.choosenColor = self.spot.choosenColor;
  //         document.querySelector("meta[name=theme-color]").setAttribute("content", self.choosenColor);

  //         console.log(self.spot.src, self.spot.name);
  //         self.currentTrack = {
  //           src: self.spot.src,
  //           title: self.spot.name,
  //           preload: 'metadata' // tell the plugin to preload metadata such as duration for this track, set to 'none' to turn off
  //         };


  //       } else {
  //         console.log("No such document!");
  //       }
  //     }).catch(function (error) {
  //       console.log("Error getting document:", error);
  //     });
  //   }// end of else exsitst in data service

  // }




  // this._DataProvider.getSpotByID(spotID)
  // .subscribe(res => {
  //   console.log(res);
  //   this.spot = res["theResult"][0]; 
  //   this.activePlayer = true;
  // console.log(this.spot);
  //   this.bgImg=this.spot.bgImg;
  // this.src=this.spot.src;
  // this.choosenColor=this.spot.choosenColor;
  // document.querySelector("meta[name=theme-color]").setAttribute("content", this.choosenColor);


  // this.currentTrack = {
  //   src: this.spot.src,
  //   title: this.spot.name,
  //   preload: 'metadata' // tell the plugin to preload metadata such as duration for this track, set to 'none' to turn off
  //  };

  // this._DataProvider.getExhibitionByID(this.spot.ExhibitionsID)
  // .subscribe(res => {
  //   console.log(res);
  //   this.exhibition = res["theResult"][0];
  //   console.log(this.exhibition);
  //   this.spotType = this.exhibition.spotType;
  //   this.autoPlay = this.exhibition.autoPlay;
  //   if(this.autoPlay){
  //     this.playShow = true;
  //   }



  //     });


  //});// end of getSpotByID

  // }



  // clickOnBtnEnter(){
  //   this.activeBoard = !this.activeBoard;

  // }


  // clickNum(numClicked) {
  //   console.log('numClicked', numClicked);
  //   if (numClicked != 'delete') {
  //     // is a number
  //     console.log('digitsIndex', this.digitsIndex);
  //     if (this.digitsIndex == 2) {
  //       console.log('ask for data');
  //       this.digits[this.digitsIndex] = numClicked;
  //       this.digitsIndex++;
  //       console.log(this.exhibition);
  //       console.log(this.id);

  //       //asking for data
  //      this._DataProvider.getSpotByExebitionCodeAndLang(this._DataProvider.Muzeum._id,this.digits[0].toString() +this.digits[1].toString() +this.digits[2].toString() ,this.exhibition.lang)
  //      .subscribe(res => {
  //         console.log(res);
  //         this.result = res["theResult"];

  //         console.log(this.result);
  //         if(this.result.length != 0){
  //           this.PauseAudio();
  //           this.bgImg=this.result[0]["bgImg"];
  //          this.spotName = this.result[0]["name"];
  //         // this.btnEnterShow=true;
  //         this.getSpotFunc(this.result[0]["_id"]);
  //         this._DataProvider.setLocalStorage('selectedSpotID',this.result[0]["_id"]);

  //          this._DataProvider.selectedSpot = this.result[0];
  //         }else{
  //           //do the not found here
  //           console.log("could not get !!");
  //         }

  //           }); 






  //     } else if (this.digitsIndex > 2) {
  //       console.log('no more -- light up the delete animation');
  //     } else {
  //       this.digits[this.digitsIndex] = numClicked;
  //       this.digitsIndex++;
  //     }//end of check index
  //   } else {
  //     // is delete
  //     console.log('digitsIndex', this.digitsIndex);
  //     if ( this.bgImg != this.exhibition.bgImg){
  //       console.log('this.bgImg !=');
  //      // this.spotName = "";
  //      // this._DataProvider.selectedSpot = {"_id":""};
  //      // this.btnEnterShow=false;
  //    //   this.bgImg = this.exhibition.bgImg;
  //      // this.activePlayer = false;

  //     }


  //     if (this.digitsIndex != 0) {
  //       this.digitsIndex--;
  //       this.digits[this.digitsIndex] = " ";
  //     } 


  //   }
  // }

}
