import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireModule } from '@angular/fire/compat';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LangPipe } from './lang.pipe';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HomePage } from './pages/home/home.page';
import { ChooseLangComponent } from './components/choose-lang/choose-lang.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { PlayerComponent } from './components/player/player.component';
import { MuzeumPage } from './pages/muzeum/muzeum.page';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { VoaInfoComponent } from './voa-info/voa-info.component';
import { MuzeumInfoComponent } from './muzeum-info/muzeum-info.component';
import { ExhibitionListPage } from './pages/exhibition-list/exhibition-list.page';
import { NumpadComponent } from './components/numpad/numpad.component';
import { NgScormPlayerModule } from 'ng-scorm-player';
import { ScormPlayerComponent } from './scorm-player/scorm-player.component';

const firebaseConfig = {
  apiKey: "AIzaSyCtdarpzLS7NhjeEy_NXq3EKrioqlMkWBY",
  authDomain: "voa-acoustiguide.firebaseapp.com",
  projectId: "voa-acoustiguide",
  storageBucket: "voa-acoustiguide.appspot.com",
  messagingSenderId: "126651269017",
  appId: "1:126651269017:web:d9cb37537301997d771d08",
  measurementId: "G-JRGGR0GXR2"
};

// const firebaseConfig ={
//   apiKey: "AIzaSyDg2AxaZkRUpMOI41GchOjLvTXon-O9a5c",
//   authDomain: "voa-ourcloud.firebaseapp.com",
//   projectId: "voa-ourcloud",
//   storageBucket: "voa-ourcloud.appspot.com",
//   messagingSenderId: "165197992766",
//   appId: "1:165197992766:web:68f3d6a88312b4ef0c926f",
//   measurementId: "G-1NGR8G62Y7"
// }
var DEBUG = false;
// ENABLE/DISABLE Console Logs
if (!DEBUG) {
  console.log = function () {};
}
 
// const firebaseConfig = {
//   apiKey: "AIzaSyAdJxUB_36gepWJXi1akHmohM8k05URemw",
//   authDomain: "voa-anu.firebaseapp.com",
//   projectId: "voa-anu",
//   storageBucket: "voa-anu.appspot.com",
//   messagingSenderId: "703525286501",
//   appId: "1:703525286501:web:1a9885ba11cc46652dcc8c",
//   measurementId: "G-M9EGLHSSBG"
// } 
export const env = firebaseConfig;


@NgModule({
  declarations: [
    PlayerComponent,
    TopMenuComponent,
    AppComponent, 
    LangPipe,
    HomePage,
    ChooseLangComponent,
    MuzeumPage,
    SideMenuComponent,
    VoaInfoComponent,
    MuzeumInfoComponent,
    ExhibitionListPage,
    PlayerComponent,
    NumpadComponent,
    ScormPlayerComponent
  ],
  imports: [
    BrowserModule, 
    CommonModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    HttpClientModule,
    // NgScormPlayerModule.forChild({debug : true})
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [{ 
    provide: RouteReuseStrategy, 
    useClass: IonicRouteStrategy 
  }],
  bootstrap: [AppComponent],
})
export class AppModule { }
