import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PlayerComponent } from './components/player/player.component';
import { ExhibitionListPage } from './pages/exhibition-list/exhibition-list.page';
import { ExhibitionPage } from './pages/exhibition/exhibition.page';
import { HomePage } from './pages/home/home.page';
import { MuzeumPage } from './pages/muzeum/muzeum.page';
import { SpotPage } from './pages/spot/spot.page';
import { WelcomePage } from './pages/welcome/welcome.page';

const routes: Routes = [
  // {
  //   path: '/',
  //   // redirectTo: '/',
  //   pathMatch: 'full',
  //   component: WelcomePage
  // },
  // { path: 'wel', component: WelcomePage, children: [], canActivate: [true] },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  // {
  //   path: 'home/:muzeum/:tour/spot/:spot', component: MuzeumPage
  // },
  // {
  //   path: 'home/:muzeum/:tour/:ex', component: ExhibitionListPage
  // },
  // {
  //   path: 'home/:muzeum/:tour', component: MuzeumPage
  // },
  // {
  //   path: 'home/:muzeum', component: HomePage
  // },
 

  {
    path: 'spot/:muzeum/:spot', component: HomePage
  },
  {
    path: 'exhibition/:muzeum/:ex', component: ExhibitionListPage
  },
  {
    path: 'museum/:muzeum', component: MuzeumPage
  },
  {
    path: 'museum/:muzeum/:tour', component: MuzeumPage
  },
  {
    path: 'home/:muzeum', component: HomePage
  },
  // {
  //   path: 'exhibition',
  //   loadChildren: () => import('./pages/exhibition/exhibition.module').then( m => m.ExhibitionPageModule)
  // },
  // {
  //   path: 'exhibition-list',
  //   loadChildren: () => import('./pages/exhibition-list/exhibition-list.module').then( m => m.ExhibitionListPageModule)
  // },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  // },
  // {
  //   path: 'muzeum',
  //   loadChildren: () => import('./pages/muzeum/muzeum.module').then( m => m.MuzeumPageModule)
  // },
  // {
  //   path: 'spot',
  //   loadChildren: () => import('./pages/spot/spot.module').then( m => m.SpotPageModule)
  // },
  // {
  //   path: 'spots-list',
  //   loadChildren: () => import('./pages/spots-list/spots-list.module').then( m => m.SpotsListPageModule)
  // },
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
