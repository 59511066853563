import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from './providers/data.service';

@Pipe({
  name: 'lang'
})
export class LangPipe implements PipeTransform {

  constructor(private _DataService: DataService) { }

  transform(value: any, args?: any): any {
    let choosen: any = this._DataService.langsSupported.find(element => element.code == value);
    if (choosen) {
      return choosen.displayName ? choosen.displayName : choosen.name;
    } else {
      return value;
    }
  }

}
