import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { ChooseLangComponent } from '../components/choose-lang/choose-lang.component';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  params = {
    muzeum: '',
    tour: '',
    ex: '',
    spot: ''
  };
  numpadOpen = false;
  sideMenu = false;
  previousUrl = '';
  currentUrl = '';
  // navToSpot = new BehaviorSubject({ id: '', muzeum: '', exhibition: '' });
  current_spot = new BehaviorSubject(null);
  stop_audio = new BehaviorSubject(false);
  muzeum_loaded = new BehaviorSubject(false);
  langObj = {
    words: {},
    direction: 'ltr',
    about_text: ''
  };
  // private _ModalController: any;
  constructor(
    // public navCtrl: NavController,
    private af: AngularFirestore, public http: HttpClient, private _ModalController: ModalController, private router: Router, private route: ActivatedRoute) {

    var self = this;

    window.addEventListener("hashchange", function (e) {
      if (e.oldURL.length > e.newURL.length) {
        if (self.player_state == 'open') {
          console.log('e.oldURL', e.oldURL);
          console.log('e.newURL', e.newURL);
          history.forward();
          self.changeState('min');
        }
        // alert("back");

      }

    });




    console.log('Hello DataProvider Provider');



  }

  langsSupported = [
    {
      "name": "Afrikaans",
      "code": "af",
      "direction": "ltr",
      "displayName": "Afrikaans"
    },
    {
      "name": "Albanian",
      "code": "sq",
      "direction": "ltr",
      "displayName": "Shqip"
    },
    {
      "name": "Amharic",
      "code": "am",
      "direction": "ltr",
      "displayName": "አማርኛ"
    },
    {
      "name": "Arabic",
      "code": "ar",
      "direction": "rtl",
      "displayName": "عربي"
    },
    {
      "name": "Armenian",
      "code": "hy",
      "direction": "ltr",
      "displayName": "Հայերեն"
    },
    {
      "name": "Azerbaijani",
      "code": "az",
      "direction": "ltr",
      "displayName": "Azərbaycanlı"
    },
    {
      "name": "Basque",
      "code": "eu",
      "direction": "ltr",
      "displayName": "Euskalduna"
    },
    {
      "name": "Belarusian",
      "code": "be",
      "direction": "ltr",
      "displayName": "Беларускі"
    },
    {
      "name": "Bengali",
      "code": "bn",
      "direction": "ltr",
      "displayName": "বাংলা"
    },
    {
      "name": "Bosnian",
      "code": "bs",
      "direction": "ltr",
      "displayName": "Bosanski"
    },
    {
      "name": "Bulgarian",
      "code": "bg",
      "direction": "ltr",
      "displayName": "Български"
    },
    {
      "name": "Catalan",
      "code": "ca",
      "direction": "ltr",
      "displayName": "Català"
    },
    {
      "name": "Cebuano",
      "code": "ceb",
      "direction": "ltr",
      "displayName": "Cebuano"
    },
    {
      "name": "Chinese",
      "code": "cmn",
      "direction": "ltr",
      "displayName": "中国人"
    },
    {
      "name": "Corsican",
      "code": "co",
      "direction": "ltr",
      "displayName": "Corsu"
    },
    {
      "name": "Croatian",
      "code": "hr",
      "direction": "ltr",
      "displayName": "Hrvatski"
    },
    {
      "name": "Czech",
      "code": "cs",
      "direction": "ltr",
      "displayName": "Čeština"
    },
    {
      "name": "Danish",
      "code": "da",
      "direction": "ltr",
      "displayName": "Dansk"
    },
    {
      "name": "Dutch",
      "code": "nl",
      "direction": "ltr",
      "displayName": "Nederlands"
    },
    {
      "name": "English",
      "code": "en",
      "direction": "ltr",
      "displayName": "English"
    },
    {
      "name": "Esperanto",
      "code": "eo",
      "direction": "ltr",
      "displayName": "Esperanto"
    },
    {
      "name": "Estonian",
      "code": "et",
      "direction": "ltr",
      "displayName": "Eesti keel"
    },
    {
      "name": "Finnish",
      "code": "fi",
      "direction": "ltr",
      "displayName": "Suomalainen"
    },
    {
      "name": "French",
      "code": "fr",
      "direction": "ltr",
      "displayName": "Français"
    },
    {
      "name": "Frisian",
      "code": "fy",
      "direction": "ltr",
      "displayName": "Frysk"
    },
    {
      "name": "Galician",
      "code": "gl",
      "direction": "ltr",
      "displayName": "Galego"
    },
    {
      "name": "Georgian",
      "code": "ka",
      "direction": "ltr",
      "displayName": "Ქართველი"
    },
    {
      "name": "German",
      "code": "de",
      "direction": "ltr",
      "displayName": "Deutsch"
    },
    {
      "name": "Greek",
      "code": "el",
      "direction": "ltr",
      "displayName": "Ελληνικά"
    },
    {
      "name": "Gujarati",
      "code": "gu",
      "direction": "ltr",
      "displayName": "ગુજરાતી"
    },
    {
      "name": "Haitian",
      "code": "ht",
      "direction": "ltr",
      "displayName": "Ayisyen"
    },
    {
      "name": "Hausa",
      "code": "ha",
      "direction": "ltr",
      "displayName": "Hausa"
    },
    {
      "name": "Hawaiian",
      "code": "haw",
      "direction": "ltr",
      "displayName": "ʻŌlelo Hawaiʻi"
    },
    {
      "name": "Hebrew",
      "code": "he",
      "direction": "rtl",
      "displayName": "עִברִית"
    },
    {
      "name": "Hindi",
      "code": "hi",
      "direction": "ltr",
      "displayName": "नहीं"
    },
    {
      "name": "Hmong",
      "code": "hmn",
      "direction": "ltr",
      "displayName": "Hmong"
    },
    {
      "name": "Hungarian",
      "code": "hu",
      "direction": "ltr",
      "displayName": "Magyar"
    },
    {
      "name": "Icelandic",
      "code": "is",
      "direction": "ltr",
      "displayName": "Íslensku"
    },
    {
      "name": "Igbo",
      "code": "ig",
      "direction": "ltr",
      "displayName": "Igbo"
    },
    {
      "name": "Indonesian",
      "code": "id",
      "direction": "ltr",
      "displayName": "Bahasa Indonesia"
    },
    {
      "name": "Irish",
      "code": "ga",
      "direction": "ltr",
      "displayName": "Gaeilge"
    },
    {
      "name": "Italian",
      "code": "it",
      "direction": "ltr",
      "displayName": "Italiano"
    },
    {
      "name": "Japanese",
      "code": "ja",
      "direction": "ltr",
      "displayName": "日本"
    },
    {
      "name": "Javanese",
      "code": "jv",
      "direction": "ltr",
      "displayName": "Basa jawa"
    },
    {
      "name": "Kannada",
      "code": "kn",
      "direction": "ltr",
      "displayName": "ಕನ್ನಡ"
    },
    {
      "name": "Kazakh",
      "code": "kk",
      "direction": "ltr",
      "displayName": "Қазақ"
    },
    {
      "name": "Khmer",
      "code": "km",
      "direction": "ltr",
      "displayName": "ខ្មែរ"
    },
    {
      "name": "Kinyarwanda",
      "code": "rw",
      "direction": "ltr",
      "displayName": "Kinyarwanda"
    },
    {
      "name": "Korean",
      "code": "ko",
      "direction": "ltr",
      "displayName": "한국인"
    },
    {
      "name": "Kurdish",
      "code": "ku",
      "direction": "rtl",
      "displayName": "Kurdî"
    },
    {
      "name": "Kyrgyz",
      "code": "ky",
      "direction": "ltr",
      "displayName": "Кыргызча"
    },
    {
      "name": "Lao",
      "code": "lo",
      "direction": "ltr",
      "displayName": "ແຮງ​ງານ"
    },
    {
      "name": "Latin",
      "code": "la",
      "direction": "ltr",
      "displayName": "Latin"
    },
    {
      "name": "Latvian",
      "code": "lv",
      "direction": "ltr",
      "displayName": "Latviski"
    },
    {
      "name": "Lithuanian",
      "code": "lt",
      "direction": "ltr",
      "displayName": "Lietuvis"
    },
    {
      "name": "Luxembourgish",
      "code": "lb",
      "direction": "ltr",
      "displayName": "Lëtzebuergesch"
    },
    {
      "name": "Macedonian",
      "code": "mk",
      "direction": "ltr",
      "displayName": "Македонски"
    },
    {
      "name": "Malagasy",
      "code": "mg",
      "direction": "ltr",
      "displayName": "Malagasy"
    },
    {
      "name": "Malay",
      "code": "ms",
      "direction": "ltr",
      "displayName": "Melayu"
    },
    {
      "name": "Malayalam",
      "code": "ml",
      "direction": "ltr",
      "displayName": "മലയാളം"
    },
    {
      "name": "Maltese",
      "code": "mt",
      "direction": "ltr",
      "displayName": "Malti"
    },
    {
      "name": "Maori",
      "code": "mi",
      "direction": "ltr",
      "displayName": "Maori"
    },
    {
      "name": "Marathi",
      "code": "mr",
      "direction": "ltr",
      "displayName": "मराठी"
    },
    {
      "name": "Mongolian",
      "code": "mn",
      "direction": "ltr",
      "displayName": "Монгол"
    },
    {
      "name": "Myanmar",
      "code": "my",
      "direction": "ltr",
      "displayName": "မြန်မာ"
    },
    {
      "name": "Nepali",
      "code": "ne",
      "direction": "ltr",
      "displayName": "नेपाली"
    },
    {
      "name": "Norwegian",
      "code": "no",
      "direction": "ltr",
      "displayName": "Norsk"
    },
    {
      "name": "Nyanja",
      "code": "ny",
      "direction": "ltr",
      "displayName": "Nyanja"
    },
    {
      "name": "Odia",
      "code": "or",
      "direction": "ltr",
      "displayName": "ଓଡିଆ"
    },
    {
      "name": "Pashto",
      "code": "ps",
      "direction": "ltr",
      "displayName": "پښتو"
    },
    {
      "name": "Persian",
      "code": "fa",
      "direction": "rtl",
      "displayName": "فارسی"
    },
    {
      "name": "Polish",
      "code": "pl",
      "direction": "ltr",
      "displayName": "Polski"
    },
    {
      "name": "Portuguese",
      "code": "pt",
      "direction": "ltr",
      "displayName": "Português"
    },
    {
      "name": "Punjabi",
      "code": "pa",
      "direction": "ltr",
      "displayName": "ਪੰਜਾਬੀ"
    },
    {
      "name": "Romanian",
      "code": "ro",
      "direction": "ltr",
      "displayName": "Română"
    },
    {
      "name": "Russian",
      "code": "ru",
      "direction": "ltr",
      "displayName": "Русский"
    },
    {
      "name": "Samoan",
      "code": "sm",
      "direction": "ltr",
      "displayName": "Samoa"
    },
    {
      "name": "Scots",
      "code": "gd",
      "direction": "ltr",
      "displayName": "Albannach"
    },
    {
      "name": "Serbian",
      "code": "sr",
      "direction": "ltr",
      "displayName": "Српски"
    },
    {
      "name": "Sesotho",
      "code": "st",
      "direction": "ltr",
      "displayName": "Sesotho"
    },
    {
      "name": "Shona",
      "code": "sn",
      "direction": "ltr",
      "displayName": "Shona"
    },
    {
      "name": "Sindhi",
      "code": "sd",
      "direction": "ltr",
      "displayName": "سنڌي"
    },
    {
      "name": "Sinhala",
      "code": "si",
      "direction": "ltr",
      "displayName": "සිංහල"
    },
    {
      "name": "Slovak",
      "code": "sk",
      "direction": "ltr",
      "displayName": "Slovenčina"
    },
    {
      "name": "Slovenian",
      "code": "sl",
      "direction": "ltr",
      "displayName": "Slovenščina"
    },
    {
      "name": "Somali",
      "code": "so",
      "direction": "ltr",
      "displayName": "Somali"
    },
    {
      "name": "Spanish",
      "code": "es",
      "direction": "ltr",
      "displayName": "Español"
    },
    {
      "name": "Sundanese",
      "code": "su",
      "direction": "ltr",
      "displayName": "Sundanese"
    },
    {
      "name": "Swahili",
      "code": "sw",
      "direction": "ltr",
      "displayName": "Kiswahili"
    },
    {
      "name": "Swedish",
      "code": "sv",
      "direction": "ltr",
      "displayName": "Svenska"
    },
    {
      "name": "Tagalog",
      "code": "tl",
      "direction": "ltr",
      "displayName": "Tagalog"
    },
    {
      "name": "Tajik",
      "code": "tg",
      "direction": "ltr",
      "displayName": "Тоҷикӣ"
    },
    {
      "name": "Tamil",
      "code": "ta",
      "direction": "ltr",
      "displayName": "தமிழ்"
    },
    {
      "name": "Tatar",
      "code": "tt",
      "direction": "ltr",
      "displayName": "Татар"
    },
    {
      "name": "Telugu",
      "code": "te",
      "direction": "ltr",
      "displayName": "తెలుగు"
    },
    {
      "name": "Thai",
      "code": "th",
      "direction": "ltr",
      "displayName": "แบบไทย"
    },
    {
      "name": "Turkish",
      "code": "tr",
      "direction": "ltr",
      "displayName": "Türkçe"
    },
    {
      "name": "Turkmen",
      "code": "tk",
      "direction": "ltr",
      "displayName": "Türkmen"
    },
    {
      "name": "Ukrainian",
      "code": "uk",
      "direction": "ltr",
      "displayName": "Українська"
    },
    {
      "name": "Urdu",
      "code": "ur",
      "direction": "rtl",
      "displayName": "اردو"
    },
    {
      "name": "Uyghur",
      "code": "ug",
      "direction": "ltr",
      "displayName": "Uyghur"
    },
    {
      "name": "Uzbek",
      "code": "uz",
      "direction": "ltr",
      "displayName": "O&#39;zbek"
    },
    {
      "name": "Vietnamese",
      "code": "vi",
      "direction": "ltr",
      "displayName": "Tiếng Việt"
    },
    {
      "name": "Welsh",
      "code": "cy",
      "direction": "ltr",
      "displayName": "Cymraeg"
    },
    {
      "name": "Xhosa",
      "code": "xh",
      "direction": "ltr",
      "displayName": "IsiXhosa"
    },
    {
      "name": "Yiddish",
      "code": "yi",
      "direction": "ltr",
      "displayName": "ייִדיש"
    },
    {
      "name": "Yoruba",
      "code": "yo",
      "direction": "ltr",
      "displayName": "Yoruba"
    },
    {
      "name": "Zulu",
      "code": "zu",
      "direction": "ltr",
      "displayName": "Zulu"
    }
  ]

  OnInit() {
    setTimeout(() => {
      this.loader = false;
    }, 300)


  }


  capsLetters(str){
    if(str){
      return str.split(' ').map(x => x[0]).join('')
    }else{
      return ''
    }
  }

  //ServerURL = "http://localhost:3000";
  ServerURL = "http://lm.experteam-game.co.il:3000";
  hide_back = false;
  // langg = localStorage.getItem('lang');
  lang = new BehaviorSubject(localStorage.getItem('lang'));
  loader = true;
  mdoalvoaopen = false;
  mdoalmuzopen = false;
  currentPage = ''


  player_state = 'close'
  changeState(state) {
    document.getElementById('player_page').scrollTop = 0;

    this.player_state = state;
    if (state == 'close') {
      this.stop_audio.next(true);
    }
    // alinalin
    // if (state == 'min') {
    //   var targetDiv: any = document.getElementById("page5").getElementsByClassName("scroll-content");
    //   console.log('targetDiv', targetDiv);
    //   targetDiv[0].scrollTop = 0;
    //   // alert('min');
    // }
  }




  fbTest(val) {
    console.log('start firebase');
    return this.af.collection('Exhibition', ref => {
      return ref.where("lang", "==", 'he')
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          //   console.log('firebase-a',a);

          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          // console.log('firebase',data);

          return { id, ...data as {} };
        });
      }));

  }

  backGroundCove = "";
  // navigationC = {
  //   home: true,
  //   numType: false,
  //   more: false,
  // }
  // spotsList;

  // showPad = false;

  selectedExebition;
  // menuShow = false;
  ExhibitionsService;
  // NowLang;
  // mapShow = false;
  direction = "ltr";
  spotType = '';
  selectedSpot: any = {
    "id": "",
    _id: '',

  };

  Muzeum = {
    _id: "",
    id: "",
    titleText: '',
    lang: [{ "code": "loading", active: '', auto_translate:false}],
    urlCode: "",
    logo: "assets/imgs/logoDEF.png",
    ExhibitionStyle: "",
    settings: {
      fontClass: '',
      type: '',
      showlistex: false,
      css: '',
      spotStyle: ''
    },
    borderRadius: '',
    border: '',
    choosenLang: '',
    boxShadow: '',
    exTextStyle: '',
    background: '',

  }
  // Muzeum =
  //   {
  //     "_id": "5b93bae8c8445104910ae283",
  //     "defaultLang": "he",
  //     "urlCode":"ComicsHolon",
  //     "ExhibitionStyle":"option2",
  //     "lang": [
  //       {
  //         "code": "en",
  //         "direction": "ltr",
  //         "name": "comics holon",
  //         "discription": "bla bla bla bla"
  //       }
  //     ],
  //     "logo": "src/ihiu/oijoi.png",
  //     "username": "holonComics",
  //     "password": "isajdoiaj",
  //     "link": "http://muses.com/holonvlalal",
  //     "advPop": {},
  //     "settings": {}
  //   };

  selectedSpotExe;

  setLangObj() {
    let obj: any;
    obj = this.Muzeum.lang.find(lang => lang.code == this.lang.getValue());
    if (obj) {
      let def_lang_obj: any = this.Muzeum.lang.find(lang => lang.code == 'en');
      console.log(this.lang.getValue());
      console.log(this.Muzeum);
      console.log(obj);
      console.log(def_lang_obj);

      for (const key in def_lang_obj.words) {
        if (!obj.words[key]) {
          obj.words[key] = def_lang_obj.words[key]
        }
      }

      this.langObj = obj;
    } else {
      this.lang.next('');
    }
  }

  modalVoa() {
    console.log('currentPage:', this.currentPage)
    if (this.currentPage == 'home') {

      this.mdoalvoaopen = true;
    }

  }

  homePage() {
    if (this.player_state == 'open') {
      this.changeState('min');
    }
    this.router.navigate(['/home/' + this.params.muzeum], { replaceUrl: true });
  }

  goBack() {
    if (this.player_state == 'open') {
      this.changeState('min');
    } else {
      // if (this.previousUrl) {
      //   this.router.navigate([this.previousUrl], { replaceUrl: true });
      // } else {
      //   this.homePage();
      // }
      if (this.currentPage == 'muzeum') {
        this.homePage();
      } else if (this.currentPage == 'exhibitionlist') {
        // if (this.params.tour) {
        //   this.router.navigate(['muzeum/' + this.params.muzeum + '/' + this.params.tour], { replaceUrl: true });
        // } else {
        //   this.router.navigate(['muzeum/' + this.params.muzeum], { replaceUrl: true });
        // }
        if (this.previousUrl) {
          this.router.navigate([this.previousUrl], { replaceUrl: true });
        } else {
          this.homePage();
        }

      }
    }
  }

  getSpotByCode() {

  }

  changeSpot(item) {
    console.log(item);
    this.current_spot.next(item);
  }

  gotoSpot(item) {
    console.log(this.params);
    this.numpadOpen = false;
    console.log(item);
    // this.selectedSpotExe = item.id;
    this.player_state = 'open';
    this.current_spot.next(item);
    // this.router.navigate(['home/' + this.params.muzeum + '/' + this.params.tour + '/' + this.params.ex + '/' + item.id]);
    let el = document.getElementById('player_page');
    el.classList.remove('slideout');
  }

  setLocalStorage(name, val) {
    localStorage.setItem(name, val);
    return true;
  }

  getLocalStorage(name) {
    return localStorage.getItem(name);
  }

  Tour_data: any;

  setStatstic(type) {
    let spot = this.current_spot.getValue();
    console.log("%c🎨 Log  %c setStatstic " + type + "", "font-size: 10px;color:#ffffff;  padding: 5px 13px; background: #9170d8; border-radius: 5px; ", " margin-left: 7px ;color: #9170d8;font-size: 10px; ")

    let data = {
      type: type,
      userwork: localStorage.getItem('userwork') ? localStorage.getItem('userwork') : 'no-user',

      muzeum: this.Muzeum.id ? this.Muzeum.id : '',
      muzeumName: this.Muzeum.urlCode ? this.Muzeum.urlCode : '',
      time: new Date().getTime(),
      lang: this.lang.getValue() ? this.lang.getValue() : '',
      spotid: spot ? spot.id : '',
      spotcode: spot ? spot.code : '',
      spotname: spot ? spot.name : '',
      ExhibitionsID: spot ? spot.ExhibitionsID : '',
      Tour: this.params.tour ? this.params.tour : 'No-Tour',
    }
    console.log('data', data)
    console.log(this.Tour_data);
    
    if (this.Tour_data) {
      if (data.Tour != this.Tour_data.id) {
        data.Tour = this.Tour_data.id;
      }
    }
    this.af.collection('logs').add(data)



  }

  // http://localhost:3000/api/ExhibitionByLang/5b93bae8c8445104910ae283/he
  getExhibitionByLang(id, lang) {
    console.log('getExhibitionByLang');
    console.log(this.ServerURL + "/api/ExhibitionByLang/" + id + "/" + lang)
    return this.http.get(this.ServerURL + "/api/ExhibitionByLang/" + id + "/" + lang);
  }


  // http://localhost:3000/api/spotID/5b96684e174f465f8f200b68
  getSpotByID(id) {
    console.log('getSpotByID', id);
    return this.http.get(this.ServerURL + "/api/spotID/" + id);
  }


  // http://localhost:3000/api/Exhibition/5b93bc0fc8445104910ae284
  getExhibitionByID(DOMid) {
    console.log('getExhibitionByID', DOMid);
    return this.http.get(this.ServerURL + "/api/Exhibition/" + DOMid);
  }

  // http://localhost:3000/api/spot/5b93bc0fc8445104910ae284/222/en
  getSpotByExebitionCodeAndLang(id, code, lang) {
    console.log('getSpotByExebitionCodeAndLang', id, code, lang);
    return this.http.get(this.ServerURL + "/api/spot/" + id + "/" + code + "/" + lang);
  }

  // http://localhost:3000/api/Muzeum/ComicsHolon
  getMuzeumByurlCode(urlCode) {
    console.log('getMuzeumByurlCode', urlCode);
    return this.http.get(this.ServerURL + "/api/Muzeum/" + urlCode);
  }




  getSpotByExebitionCodeAndLangFB(id, code, lang) {
    console.log('getSpotByExebitionCodeAndLang', id, code, lang);

    return this.af.collection('spots', ref => {
      return ref.where("muzeum", "==", id)
        .where("code", "==", code)
        .where("lang", "==", lang)
    }).snapshotChanges().pipe(
      map(actions => {

        return actions.map(a => {
          const data: any = a.payload.doc.data();
          console.log(data);
          const id = a.payload.doc.id;
          data.id = id;
          data._id = id;
          return { id, ...data };
        });
      }));


    //   return this.http.get(this.ServerURL + "/api/spot/" + id + "/" + code + "/"+ lang);
  }



  getSpotByExebitionCodeAndLangAndExFB(id, code, lang, ex) {
    console.log('getSpotByExebitionCodeAndLang', id, code, lang, ex);

    return this.af.collection('spots', ref => {
      return ref.where("muzeum", "==", id)
        .where("code", "==", code)
        .where("lang", "==", lang)
        .where("ExhibitionsID", "==", ex)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data: any = a.payload.doc.data();
          const id = a.payload.doc.id;
          data.id = id;
          data._id = id;

          console.log('getExhibitionByLangFB-firebase', data);
          console.log('getExhibitionByLangFB-firebase', id);
          return { id, ...data };
        });
      }));


    //   return this.http.get(this.ServerURL + "/api/spot/" + id + "/" + code + "/"+ lang);
  }
  getTourById(id) {
    // console.log('getMuzeumByurlCode');
    // return this.af.collection('Tours', ref => {
    //   return ref.where("id", "==", id)
    // }).snapshotChanges().pipe(
    //   map(actions => {
    //     return actions.map(a => {
    //       const data = a.payload.doc.data();
    //       const id = a.payload.doc.id;
    //       console.log('getExhibitionByLangFB-firebase', data);
    //       return { id, ...data as {} };
    //     });
    //   }));
    console.log('getMuzeumByurlCode', id);
    return this.af.collection('Tours').doc(id).get();
  }
  getTourByUrlcode(id) {
    console.log('getTourByUrlcode', id);
    return this.af.collection('Tours', ref => {
      return ref.where("Urlcode", "==", id)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          console.log('getExhibitionByLangFB-firebase', data);
          return { id, ...data as {} };
        });
      }));
  }


  getTours(id, lang) {
    console.log('getMuzeumByurlCode');
    return this.af.collection('Tours', ref => {
      return ref.where("muzeum", "==", id).where("lang", "==", lang).where("active", "==", true)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          console.log('getExhibitionByLangFB-firebase', data);
          return { id, ...data as {} };
        });
      }));
  }


  getExhibitionByLangAndTourFB(id, lang, tour) {
    console.log('getMuzeumByurlCode', id, lang, tour);
    return this.af.collection('Exhibition', ref => {
      return ref.where("muzeum", "==", id).where("lang", "==", lang)
        .where("tour", "==", tour)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          console.log('getExhibitionByLangFB-firebase', data);
          return { id, ...data as {} };
        });
      }));
  }
  getExhibitionByLangFB(id, lang) {
    console.log('getMuzeumByurlCode');
    return this.af.collection('Exhibition', ref => {
      return ref.where("muzeum", "==", id).where("lang", "==", lang)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          console.log('getExhibitionByLangFB-firebase', data);
          return { id, ...data as {} };
        });
      }));
  }

  getExhibitionByIdfirestore(ex) {
    console.log('getMuzeumByurlCode');
    return this.af.collection('Exhibition', ref => {
      return ref.where("id", "==", ex)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          console.log('getExhibitionByLangFB-firebase', data);
          return { id, ...data as {} };
        });
      }));
  }

  choosenEx: any;
  ExhibitionFBcol = this.af.collection('Exhibition');
  FBcol = this.af;


  getSpotsByExhibitionIDFB(DOMid) {
    console.log('getSpotsByExhibitionIDFB', DOMid);
    return this.af.collection('spots', ref => {
      return ref.where("ExhibitionsID", "==", DOMid)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          let data = a.payload.doc.data();
          const id = a.payload.doc.id;
          data['id'] = a.payload.doc.id;
          console.log('firebase', data);
          return { id, ...data as {} };
        });
      }));
  }


  getExhibitionByIDFB(DOMid) {
    console.log('getExhibitionByIDFB', DOMid);
    this.af.collection('Exhibition').doc(DOMid).ref.get().then(function (doc) {
      if (doc.exists) {
        console.log("Document data:", doc.data());
        this.choosenEx = doc.data();
      } else {
        console.log("No such document!");
        return { status: false };

      }
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

    //  return  this.af.collection('Exhibition').doc(DOMid).valueChanges();

  }

  // checkLang(params) {
  //   console.log('checkLang', params);
  //   let lang = localStorage.getItem('lang');
  //   if (!lang) {
  //     // this.openModal(ChooseLangComponent);
  //   }
  // }


  async openModal(comp) {
    // this.shopS.dismissModalController();
    console.log('OPEN MODAL');

    const modal = await this._ModalController.create({
      component: comp,
      swipeToClose: true,
      cssClass: 'modal_fullscreen',
      mode: "ios",

    });

    await modal.present();
    return modal;
  }

  // async openModal(comp) {
  //   // const modal = await this._ModalController.create({
  //   //   component: comp,
  //   //   swipeToClose: true,
  //   //   cssClass: 'modalLookLikeNativ',
  //   //   mode: "ios",

  //   // });

  //   // await modal.present();
  //   // return modal;

  //   const modal = this._ModalController.create(comp);
  //   modal.present();
  // }


  // handleParams(params) {
  //   if (params.muzeum && this.params.muzeum != params.muzeum) {
  //     this.getMuzeumByurlCodeFB(params.muzeum).subscribe((res: any) => {
  //       console.log("TopMenuComponent---res", res);
  //       if (res.length != 0) {
  //         this.Muzeum = res[0];
  //         this.setLangObj();
  //         this.muzeum_loaded.next(true);
  //       }
  //     });
  //     this.params.muzeum = params.muzeum;
  //   }
  // }


  getMuzeumByurlCodeFB(urlCode) {
    console.log('getMuzeumByurlCode', urlCode);
    return this.af.collection('muzeums', ref => {
      return ref.where("urlCode", "==", urlCode)
    }).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          console.log('firebase', data);
          return { id, ...data as {} };
        });
      }))
    // .subscribe((res: any) => {
    //   console.log("TopMenuComponent---res", res);
    //   console.log("TopMenuComponent---res", res.length);
    //   if (res.length != 0) {
    //     this.Muzeum = res[0];
    //     this.muzeum_loaded.next(true);
    //     this.setLangObj();
    //   }
    // });
  }

  getAllMuseamdata() {
    return this.af.collection('spots').snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          console.log('firebase', data);
          return { id, ...data as {} };
        });
      }));
  }


  // get the current lang
  getMuzeumData() {
    console.log('getMuzeumData', this.lang.getValue());
    console.log('this.Muzeum', this.Muzeum);
    let langFrom
    langFrom = this.Muzeum.lang.filter(element => element.code == this.lang.getValue());
    console.log('langFrom', langFrom);

    this.direction = langFrom[0].direction;

    let returnItem = langFrom[0];
    // console.log(this.Muzeum.boxShadow);
    returnItem["ExhibitionStyle"] = this.Muzeum.ExhibitionStyle;
    returnItem["borderRadius"] = this.Muzeum.borderRadius;
    returnItem["border"] = this.Muzeum.border;
    returnItem["boxShadow"] = this.Muzeum.boxShadow;
    returnItem["exTextStyle"] = this.Muzeum.exTextStyle;
    returnItem["background"] = this.Muzeum.background;
    return returnItem;
  }
}
