import { Component, OnInit } from '@angular/core';
import { DataService } from '../providers/data.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  constructor(public _DataProvider: DataService) { }

  ngOnInit() { }

  closeMenu() {
    this._DataProvider.sideMenu = false;
  }

  menuClick(ev) {
    ev.stopPropagation();
  }

  homePage() {
    this.closeMenu();
    this._DataProvider.homePage();
  }

  changeLang() {
    this.closeMenu();
    this._DataProvider.lang.next('');
  }

  modalVoa() {
    this.closeMenu();
    this._DataProvider.mdoalvoaopen = true;
  }

  modalMuz() {
    this.closeMenu();
    this._DataProvider.mdoalmuzopen = true;
  }

}
