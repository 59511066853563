import { Component, OnInit } from '@angular/core';
import { DataService } from '../providers/data.service';

@Component({
  selector: 'app-muzeum-info',
  templateUrl: './muzeum-info.component.html',
  styleUrls: ['./muzeum-info.component.scss'],
})
export class MuzeumInfoComponent implements OnInit {

  constructor(public _DataProvider: DataService) { }

  ngOnInit() {}

  closeModal() {
    this._DataProvider.mdoalmuzopen = false;
  }

}
