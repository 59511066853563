import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/providers/data.service';

@Component({
  selector: 'app-exhibition-list',
  templateUrl: './exhibition-list.page.html',
  styleUrls: ['./exhibition-list.page.scss'],
})
export class ExhibitionListPage {

  // lang;
  showBack = true;
  // muzeum;
  // MuzeumData = {
  //   name: '',
  //   discription: '',
  //   ExhibitionStyle: ''
  // };
  backGroundCove = "";
  textDataex = "";
  id;
  urlCode;
  exhibition;
  spots: any;
  // params.ex: any;
  Exhibitions;
  loaderPage = false;
  loaderPageDisplayNone = false;
  oneExe = false;
  // public navCtrl: NavController, public navParams:NavParams
  constructor(private sanitizer: DomSanitizer, public _DataProvider: DataService, private route: ActivatedRoute) {

    // this.lang = this._DataProvider.getLocalStorage('lang');
    // this.muzeum = this._DataProvider.getLocalStorage('muzeum');
    // this.params.ex = this._DataProvider.getLocalStorage('exhibitionID');

    this.route.paramMap.subscribe((params: any) => {
      console.log(params);
      this.params = params.params;
      this._DataProvider.params = this.params;
    });

    // this._DataProvider.navigationC.home = true;
    // this._DataProvider.navigationC.more = false;
    // this._DataProvider.navigationC.numType = false;


  }
  cssEX: any = '';

  textDataexx= '';

  currentPage
  langSub;
  params;

  getData() {
    if (this._DataProvider.Muzeum.settings.css) {
      this.cssEX = ` <style>`;
      this.cssEX += this._DataProvider.Muzeum.settings.css;
      this.cssEX += ` </style>`;
      this.cssEX = this.sanitizer.bypassSecurityTrustHtml(this.cssEX);
      console.log('CSS--------', this.cssEX);

    }

    // this.MuzeumData = this._DataProvider.getMuzeumData();
    console.log('FB - Muzeum._id', this._DataProvider.Muzeum);
    // console.log('FB - this.lang', this.lang);


    console.log("------- ------- ------ ", this._DataProvider.choosenEx);

    this._DataProvider.getExhibitionByIdfirestore(this.params.ex).subscribe(ex => {

      console.log('eX',ex);
      var s: any = ex;
      if(s[0]){
        this.textDataexx = s[0].name;
      }

      if (s[0] && s[0].textData) {
        this.textDataex = s[0].textData;

      }



    })

    // console.log(this.params.ex);

    this._DataProvider.getSpotsByExhibitionIDFB(this.params.ex)
      .subscribe(res2 => {
        var res: any = res2;
        console.log(res);

        if (res.length > 1) {

          res = res.sort((a, b) => parseInt(a.order) - parseInt(b.order));
        }
        // res = res.sort((a, b) => { if (parseInt(a.code) < 0) {return -1; } if (parseInt(b.code) <0 ) {return 1;} return parseInt(b.code) - parseInt(a.code) });

        this.spots = res;
        // this._DataProvider.spotsList = res;
        this.loaderPage = true;

        console.log("ex", this.params.ex);
        setTimeout(() => {
          this.loaderPageDisplayNone = true;
        }, 1300);
        // console.log(this.spots);

      });

    console.log(this.Exhibitions);
    // console.log(this.MuzeumData);
    // console.log(this.muzeum);
    // console.log(this.lang);

    // });



    console.log(this.params.muzeum);

    // alinalin
    // this._DataProvider.getMuzeumByurlCode(this.params.muzeum)
    //   .subscribe(res => {
    //     console.log(res["theResult"][0]);
    //     this._DataProvider.Muzeum = res["theResult"][0];

    //     this.MuzeumData = this._DataProvider.getMuzeumData();

    //     this._DataProvider.getExhibitionByLang(this._DataProvider.Muzeum._id, this._DataProvider.lang.getValue())
    //       .subscribe(res => {
    //         console.log(res["theResult"]);
    //         this.Exhibitions = res["theResult"];
    //         this._DataProvider.ExhibitionsService = res["theResult"];
    //         console.log(this.Exhibitions);
    //         console.log(this.MuzeumData);
    //         // console.log(this.muzeum);
    //         // console.log(this.lang);

    //       });
    //   });
  }

  initPage() {
    if (!this._DataProvider.Muzeum.id || this._DataProvider.Muzeum.urlCode != this.params.muzeum) {
      this._DataProvider.getMuzeumByurlCodeFB(this.params.muzeum).subscribe((res: any) => {
        console.log("TopMenuComponent---res", res);
        if (res.length) {
          this._DataProvider.Muzeum = res[0];
          console.log("EXLIST - SETLANGOBJ");
          let lang_obj = this._DataProvider.Muzeum.lang.find(lang => lang.code == this._DataProvider.lang.getValue());
          if (lang_obj) {
            this._DataProvider.setLangObj();
            this.getData();
          } else {
            this._DataProvider.lang.next('');
          }
        }
        // if (lang && muzeum) {
        // }
        else {
          this._DataProvider.loader = false;
        }
      });
    } else {
      this.getData();
    }
  }

  ionViewDidEnter() {

    if (!this._DataProvider.lang.getValue()) {
      this.langSub = this._DataProvider.lang.subscribe(val => {
        console.log(val);
        if (val) {
          this.initPage();
        }
      });
    } else {
      this.initPage();
    }


    this._DataProvider.currentPage = 'exhibitionlist';

    // this.muzeum_sub = this._DataProvider.muzeum_loaded.subscribe(val => {
    //   if (val) {
    //     // this._DataProvider.getMuzeumByurlCodeFB(this.muzeum).subscribe((res: any) => {
    //       // console.log("res", res);
    //       // console.log(res[0]);
    //       // this._DataProvider.Muzeum = res[0];
    //       // this._DataProvider.setLangObj();
    //       // this._DataProvider.backGroundCove = res[0].background;
    //       // this.backGroundCove = res[0].background;


    //       if (this._DataProvider.Muzeum.settings.css) {
    //         this.cssEX = ` <style>`;
    //         this.cssEX += this._DataProvider.Muzeum.settings.css;
    //         this.cssEX += ` </style>`;
    //         this.cssEX = this.sanitizer.bypassSecurityTrustHtml(this.cssEX);
    //         console.log('CSS--------', this.cssEX);

    //       }

    //       this.MuzeumData = this._DataProvider.getMuzeumData(this.lang);
    //       console.log('FB - Muzeum._id', this._DataProvider.Muzeum);
    //       console.log('FB - this.lang', this.lang);


    //       console.log("------- ------- ------ ", this._DataProvider.choosenEx);

    //       this._DataProvider.getExhibitionByIdfirestore(this.params.ex).subscribe(ex => {


    //         var s: any = ex;
    //         if (s[0] && s[0].textData) {

    //           this.textDataex = s[0].textData;

    //         }



    //       })

    //       this._DataProvider.getSpotsByExhibitionIDFB(this.params.ex)
    //         .subscribe(res2 => {
    //           var res: any = res2;
    //           if (res.length > 1) {

    //             res = res.sort((a, b) => parseInt(a.order) - parseInt(b.order));
    //           }
    //           // res = res.sort((a, b) => { if (parseInt(a.code) < 0) {return -1; } if (parseInt(b.code) <0 ) {return 1;} return parseInt(b.code) - parseInt(a.code) });

    //           this.spots = res;
    //           this._DataProvider.spotsList = res;
    //           this.loaderPage = true;

    //           console.log("ex", this.params.ex);
    //           setTimeout(() => {
    //             this.loaderPageDisplayNone = true;
    //           }, 1300);
    //           console.log(this.spots);

    //         });

    //       console.log(this.Exhibitions);
    //       console.log(this.MuzeumData);
    //       console.log(this.muzeum);
    //       console.log(this.lang);

    //     // });




    //     this._DataProvider.getMuzeumByurlCode(this.muzeum)
    //       .subscribe(res => {
    //         console.log(res["theResult"][0]);
    //         this._DataProvider.Muzeum = res["theResult"][0];

    //         this.MuzeumData = this._DataProvider.getMuzeumData(this.lang);

    //         this._DataProvider.getExhibitionByLang(this._DataProvider.Muzeum._id, this.lang)
    //           .subscribe(res => {
    //             console.log(res["theResult"]);
    //             this.Exhibitions = res["theResult"];
    //             this._DataProvider.ExhibitionsService = res["theResult"];
    //             console.log(this.Exhibitions);
    //             console.log(this.MuzeumData);
    //             console.log(this.muzeum);
    //             console.log(this.lang);

    //           });
    //       });
    //   }
    // })



    // this._DataProvider.getExhibitionByIDFB(this.params.ex)
    // .subscribe(res => {
    //   console.log("----------> ------> ",res)

    // })

    // this._DataProvider.showPad = false;
    // this._DataProvider.menuShow = false;
    // this._DataProvider.mapShow = false;
    // this._DataProvider.NowLang = this._DataProvider.lang.getValue();
    // this.muzeum = this._DataProvider.getLocalStorage('muzeum');
    // console.log(this.muzeum);

    console.log("-----> new new new new ", this._DataProvider.Muzeum.choosenLang)

    // alinalin
    // if (this.muzeum) {
    //   this._DataProvider.getMuzeumByurlCodeFB(this.muzeum).subscribe((res: any) => {
    //     console.log("res", res);
    //     console.log(res[0]);
    //     this._DataProvider.Muzeum = res[0];
    //     this._DataProvider.setLangObj();
    //     this._DataProvider.backGroundCove = res[0].background;
    //     this.backGroundCove = res[0].background;


    //     if (res[0].settings.css) {
    //       this.cssEX = ` <style>`;
    //       this.cssEX += res[0].settings.css;
    //       this.cssEX += ` </style>`;
    //       this.cssEX = this.sanitizer.bypassSecurityTrustHtml(this.cssEX);
    //       console.log('CSS--------', this.cssEX);

    //     }

    //     this.MuzeumData = this._DataProvider.getMuzeumData(this.lang);
    //     console.log('FB - Muzeum._id', this._DataProvider.Muzeum);
    //     console.log('FB - this.lang', this.lang);


    //     console.log("------- ------- ------ ", this._DataProvider.choosenEx);

    //     this._DataProvider.getExhibitionByIdfirestore(this.params.ex).subscribe(ex => {


    //       var s: any = ex;
    //       if (s[0] && s[0].textData) {

    //         this.textDataex = s[0].textData;

    //       }



    //     })

    //     this._DataProvider.getSpotsByExhibitionIDFB(this.params.ex)
    //       .subscribe(res2 => {
    //         var res: any = res2;
    //         if (res.length > 1) {

    //           res = res.sort((a, b) => parseInt(a.order) - parseInt(b.order));
    //         }
    //         // res = res.sort((a, b) => { if (parseInt(a.code) < 0) {return -1; } if (parseInt(b.code) <0 ) {return 1;} return parseInt(b.code) - parseInt(a.code) });

    //         this.spots = res;
    //         this._DataProvider.spotsList = res;
    //         this.loaderPage = true;

    //         console.log("ex", this.params.ex);
    //         setTimeout(() => {
    //           this.loaderPageDisplayNone = true;
    //         }, 1300);
    //         console.log(this.spots);

    //       });

    //     console.log(this.Exhibitions);
    //     console.log(this.MuzeumData);
    //     console.log(this.muzeum);
    //     console.log(this.lang);

    //   });
    // }




    // this._DataProvider.getMuzeumByurlCode(this.muzeum)
    // .subscribe(res => {
    //   console.log(res["theResult"][0]);
    //   this._DataProvider.Muzeum = res["theResult"][0];

    //   this.MuzeumData = this._DataProvider.getMuzeumData(this.lang);

    //   this._DataProvider.getExhibitionByLang(this._DataProvider.Muzeum._id,this.lang)
    //   .subscribe(res => {
    //     console.log(res["theResult"]);
    //     this.Exhibitions = res["theResult"];
    //     this._DataProvider.ExhibitionsService= res["theResult"];
    // console.log(this.Exhibitions);
    // console.log(this.MuzeumData);
    // console.log(this.muzeum);
    // console.log(this.lang);

    //       });
    //     });










    // Put here the code you want to execute
  }

  // gotoSpot(item) {
  //   console.log(item);
  //   this._DataProvider.selectedSpotExe = item.id;
  //   this._DataProvider.player_state = 'open';
  //   this._DataProvider.current_spot.next({ selectedSpotID: item.id })
  //   console.log(this._DataProvider.selectedSpotExe);
  //   // console.log(this.muzeum);
  //   this._DataProvider.setLocalStorage('selectedSpotID', item.id);
  //   let el = document.getElementById('page5');
  //   el.classList.remove('slideout');

  //   // this.navCtrl.push(SpotPage, {
  //   //   muzeum:this.muzeum,
  //   //   id:this._DataProvider.selectedSpotExe,
  //   //   exhibition:item.ExhibitionsID,

  //   // })

  // }

  // enterSpotsList(select) {
  //   this._DataProvider.selectedExebition = select;
  //   this._DataProvider.setLocalStorage('exhibitionID', select.id);
  //   this._DataProvider.setLocalStorage('spotType', select.spotType);
  //   console.log("alinalin");
  //   // alinalin
  //   // this.navCtrl.push(SpotsListPage, {
  //   //   muzeum: this.muzeum,
  //   // })

  // }

  // enterExhibitionGlobal() {
  //   console.log('this.Exhibitions[0]', this.Exhibitions[0]);
  //   // console.log('this.muzeum', this.muzeum);
  //   this._DataProvider.selectedExebition = this.Exhibitions[0];
  //   this._DataProvider.setLocalStorage('exhibitionID', this.Exhibitions[0].id);
  //   console.log("alinalin");
  //   // alinalin
  //   // this.navCtrl.push(ExhibitionPage, {
  //   //   muzeum: this.muzeum,
  //   // })
  // }


  // goToCode() {

  //   this._DataProvider.selectedExebition = this.Exhibitions[0];
  //   this._DataProvider.setLocalStorage('exhibitionID', this.Exhibitions[0].id);
  //   this._DataProvider.setLocalStorage('spotType', this.Exhibitions[0].spotType);

  //   console.log("alinalin");
  //   // alinalin
  //   // this.navCtrl.push(ExhibitionPage, {
  //   //   muzeum: this.muzeum,
  //   // })
  // }

  // enterExhibition(select) {
  //   console.log(select);
  //   this._DataProvider.selectedExebition = select;
  //   this._DataProvider.setLocalStorage('exhibitionID', select.id);
  //   this._DataProvider.setLocalStorage('spotType', select.spotType);
  //   console.log("alinalin");
  //   // alinalin
  //   // this.navCtrl.push(ExhibitionPage, {
  //   //   muzeum: this.muzeum,
  //   // })

  // }

  // goBack() {
  //   setTimeout(() => {
  //     console.log("alinalin");
  //     // alinalin
  //     // this.navCtrl.setRoot(MuzeumPage, {
  //     //   muzeum: this.muzeum,
  //     // })
  //   }, 200);
  // }

  // ulClass() {
  //   if (this.MuzeumData.ExhibitionStyle == 'option2') {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

}
