import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/providers/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy {

  lang = [];
  Tours = [];
  urlCode;
  params;
  langSub: Subscription;

  // public navCtrl: NavController, public navParams: NavParams,
  constructor(public _DataProvider: DataService, private route: ActivatedRoute, private router: Router) {
    // console.log(this.navParams);
    // this.params = this.navParams.data;

    this.route.paramMap.subscribe((params: any) => {
      console.log(params);
      this.params = params.params;
      this._DataProvider.params = this.params;
    });


    // console.log('navParams-muzeum', this.navParams.get('muzeum'));
    // this.urlCode = this.navParams.get('muzeum');
    // console.log('', this._DataProvider.Muzeum);
    // console.log(this.urlCode);
  }


  initPage(lang) {
    this._DataProvider.setStatstic("login");
    this._DataProvider.currentPage = 'home';

    if (!this._DataProvider.Muzeum.id || this._DataProvider.Muzeum.urlCode != this.params.muzeum) {
      this._DataProvider.getMuzeumByurlCodeFB(this.params.muzeum).subscribe((res: any) => {
        console.log("TopMenuComponent---res", res);
        if (res.length) {
          this._DataProvider.Muzeum = res[0];
          console.log("HOME - SETLANGOBJ");
          let lang_obj = this._DataProvider.Muzeum.lang.find(lang => lang.code == this._DataProvider.lang.getValue());
          if (lang_obj) {
            this._DataProvider.setLangObj();
            this.getTours(lang);
          } else {
            this._DataProvider.lang.next('');
          }
        }
        else {
          this._DataProvider.loader = false;
        }
      });
    } else {
      this.getTours(lang);
    }

    console.log('', this._DataProvider.Muzeum);
    console.log(this.urlCode);

    // this._DataProvider.menuShow = true;
    // this._DataProvider.mapShow = true;
    // this._DataProvider.showPad = false;
  }

  ionViewDidEnter() {

    if (this._DataProvider.lang.getValue()) {
      this.initPage(this._DataProvider.lang.getValue());
    }
    this.langSub = this._DataProvider.lang.subscribe(val => {
      console.log(val);
      if (val) {
        this.initPage(val);
      }
    });
    // } 
    // else {
    //   this.initPage(this._DataProvider.lang.getValue());
    // }

  }// emd of ionViewDidLoad


  // enterToMuzium(lang) {
  //   setTimeout(() => {
  //     console.log('lang', lang);
  //     this._DataProvider.setLocalStorage('lang', lang);
  //     // this._DataProvider.lang = lang;
  //     this._DataProvider.setLocalStorage('muzeum', this.urlCode);

  //     let obj: any;
  //     obj = this._DataProvider.Muzeum.lang.find(langg => langg.code == lang);
  //     this._DataProvider.langObj = obj;
  //     this.getTours(lang);
  //     this._DataProvider.setStatstic("Enter lang")

  //   }, 200);

  //   //this.navCtrl.push(MuzeumPage, {lang: lang,MuzeumID:this.PublicMuzeums._id});
  // }

  checkSpot() {
    if (this.params.spot) {
      this._DataProvider.getSpotByExebitionCodeAndLangFB(this._DataProvider.Muzeum.id, this.params.spot, this._DataProvider.lang.getValue()).subscribe(res => {
        console.log(res);
        if (res[0]) {
          this._DataProvider.gotoSpot(res[0]);
        }
      });
    }
  }
  
  auto_translate = false;


  getTours(lang) {
    console.log("getTours");

    this.checkSpot();
    this._DataProvider.hide_back = false;
    this._DataProvider.getTours(this._DataProvider.Muzeum.id, lang).subscribe((res: any) => {
      console.log(res);

      this._DataProvider.loader = false;
      if (res.length > 0) {
        res = res.sort((a, b) => (parseInt(a.order) < parseInt(b.order) ? -1 : 1));
        let index_of_translate = res.findIndex(l => (l.auto_translate == true && l.active));
        console.log("index_of_translate", index_of_translate);
        if(index_of_translate != -1){
          this.auto_translate = true;
        }


        this.Tours = res;
      } else {
        // this._DataProvider.loader = false;
        // this._DataProvider.setLocalStorage('Tour', '');
        // this._DataProvider.setLocalStorage('TourName', '');
        console.log('alinalin');
        this.router.navigate(['museum/' + this.params.muzeum], { replaceUrl: true });

        // alinalin
        // this.navCtrl.push(MuzeumPage, {
        //   muzeum: this._DataProvider.Muzeum.urlCode
        // })
      }
    })
  }

  GoToTour(item) {
    // setTimeout(() => {
    console.log(item);

    // this._DataProvider.setLocalStorage('Tour', item.id);
    // this._DataProvider.setLocalStorage('TourName', item.name);
    console.log('alinalin');
    this.router.navigate(['museum/' + this.params.muzeum + '/' + item.id], { replaceUrl: true });
    // alinalin
    // this.navCtrl.push(MuzeumPage, {
    //   muzeum: this._DataProvider.Muzeum.urlCode
    // })



    // }, 200);
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if (this.langSub) {
      this.langSub.unsubscribe();
    }
  }
}
