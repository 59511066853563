import { Component, OnInit, Input } from '@angular/core';
import { NgScormPlayerService } from 'ng-scorm-player'

@Component({
  selector: 'app-scorm-player',
  templateUrl: './scorm-player.component.html',
  styleUrls: ['./scorm-player.component.scss'],
})
export class ScormPlayerComponent implements OnInit {
  @Input() iframe: string; 
  constructor(
    public scormPlayer:NgScormPlayerService
  ) { }
  scormResult:any;
  urlLaunchPage = ''
  urlDirOfSco = '../assets/demo/';

  ngOnInit() {

    setTimeout(() => {
      this.scormPlayer.initializeEvent.subscribe(val => { console.log('initializeEvent:', val); });
      this.scormPlayer.setValueEvent.subscribe(val => { console.log('setValueEvent:', val); }); 
      this.scormPlayer.getValueEvent.subscribe(val => { console.log('getValueEvent:', val); });
      this.scormPlayer.finishEvent.subscribe(val => { console.log('finishEvent:', val); }); 
      this.scormPlayer.commitEvent.subscribe(val => { console.log('commitEvent:', val); });  
    }, 2000);

  }

}
