import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/providers/data.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-choose-lang',
  templateUrl: './choose-lang.component.html',
  styleUrls: ['./choose-lang.component.scss'],
})
export class ChooseLangComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer,public _DataProvider: DataService) { }
  auto_translate = false;
  cssEX: any = '';

  ngOnInit() {
    console.log(this._DataProvider.params.muzeum);
    console.log(this._DataProvider.Muzeum.id);

    if (!this._DataProvider.Muzeum.id) {





      this._DataProvider.getMuzeumByurlCodeFB(this._DataProvider.params.muzeum).subscribe((res: any) => {
        console.log("TopMenuComponent---res", res);
        if (res.length) {
          this._DataProvider.Muzeum = res[0];

          if (this._DataProvider.Muzeum.settings.css) {
            this.cssEX = ` <style>`;
            this.cssEX += this._DataProvider.Muzeum.settings.css;
            this.cssEX += ` </style>`;
            this.cssEX = this.sanitizer.bypassSecurityTrustHtml(this.cssEX);
            console.log('CSS--------', this.cssEX);
          }


          let index_of_translate = this._DataProvider.Muzeum.lang.findIndex(l => (l.auto_translate == true && l.active));
          console.log("index_of_translate", index_of_translate);
          if(index_of_translate != -1){
            this.auto_translate = true;
          }
          console.log("choose1 - SETLANGOBJ");
          let lang_obj = this._DataProvider.Muzeum.lang.find(lang => lang.code == this._DataProvider.lang.getValue());
          if (lang_obj) {
            this._DataProvider.setLangObj();
          }
        } else {
       
          this._DataProvider.loader = false;
        }
      });
    }else{
      let index_of_translate = this._DataProvider.Muzeum.lang.findIndex(l => (l.auto_translate == true && l.active));
      console.log("index_of_translate", index_of_translate);
      if(index_of_translate != -1){
        this.auto_translate = true;
      }
    }
  }

  chooseLang(lang) {
    localStorage.setItem('lang', lang);
    this._DataProvider.lang.next(lang.toString());
    this._DataProvider.setLangObj();
    this._DataProvider.homePage();
    this.checkSpotOpen();
    this._DataProvider.setStatstic("Enter lang");

  }

  checkSpotOpen() {
    console.log(this._DataProvider.current_spot.getValue());

    if (this._DataProvider.current_spot.getValue()) {
      let spot: any = this._DataProvider.current_spot.getValue();
      this._DataProvider.getSpotByExebitionCodeAndLangFB(this._DataProvider.Muzeum.id, spot.code, this._DataProvider.lang.getValue()).subscribe(res => {
        console.log(res);
        if (res[0]) {
          this._DataProvider.changeSpot(res[0]);
        }
      });
    }
  }

}
