import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/providers/data.service';

@Component({
  selector: 'app-numpad',
  templateUrl: './numpad.component.html',
  styleUrls: ['./numpad.component.scss'],
})
export class NumpadComponent implements OnInit {

  id;
  bgImg = " ";
  exhibition;
  nosound = false;
  digits = ["·", "·", "·"];
  digitsIndex = 0;
  result;
  exhibitionNname = "";
  spotName = "";
  btnEnter = "";
  // codeTXT = ":קוד מיקום";
  exhibitionColor = "";
  btnEnterShow = false;
  urlCode;
  TourList = []
  correctspot;
  tour;
  lang;
  spotCode = '···';

  // public navCtrl: NavController, public navParams: NavParams
  constructor(public _DataProvider: DataService) {
    //  this.id = this.navParams.get('id');

    console.log("alinalin");
    //alinalin

    // this.urlCode = this.navParams.get('muzeum');
    // this.id = this._DataProvider.getLocalStorage('exhibitionID');

    console.log(this.urlCode, this.id);
    // this.lang = this._DataProvider.getLocalStorage('lang');
    // if (this._DataProvider.lang.getValue() == 'en') {
    //   this.codeTXT = "Enter code : ";
    // }
    // this._DataProvider.navigationC.home = false;
    // this._DataProvider.navigationC.more = false;
    // this._DataProvider.navigationC.numType = true;
  }


  dismiss() {
    this._DataProvider.numpadOpen = false;
  }
  ngOnInit(): void {

  }

  ionViewDidLoad() {
    //  this.exhibition = this._DataProvider.getExhibitionByID(this.id);
    // this.id = localStorage.getItem('exhibitionID');
    // console.log('this.id', this.id);


    // this.tour = this._DataProvider.getLocalStorage('Tour');
    // alinalin
    // if (this.tour) {
    //   this.loadTourList();
    // }
    // this._DataProvider.showPad = false;

    // console.log(this._DataProvider.ExhibitionsService);
    // if (this.id) {

      // console.log('this.id', this.id);
      //  this._DataProvider.getExhibitionByID(this.id)
      //var res = this._DataProvider.getExhibitionByIDFB(this.id);


      //    console.log('res: --- ---- ',res);

      // this._DataProvider.ExhibitionFBcol.doc(this.id).ref.get().then(function(doc) {
      //   if (doc.exists) {
      //       console.log("Document data:", doc.data());
      //       self.exhibition = doc.data();
      //       console.log(self.exhibition);
      //       console.log('self._DataProvider.Muzeum',self._DataProvider.Muzeum);
      //       self.bgImg = self._DataProvider.Muzeum.background;
      //       self.exhibitionNname = self.exhibition.name;
      //       self.btnEnter =  self.exhibition.btnEnter;
      //       self.exhibitionColor = self.exhibition.choosenColor;
      //       console.log(self.exhibition.codeTXT);
      //       if(self._DataProvider.Muzeum.hasOwnProperty('codeTXT')){
      //         console.log(self.exhibition.codeTXT);
      //         self.codeTXT = self._DataProvider.Muzeum.codeTXT;
      //       } 
      //       console.log(self.codeTXT);
      //   } else {
      //       console.log("No such document!");
      //   }
      // }).catch(function(error) {
      //     console.log("Error getting document:", error);
      // });





    // }//end of if

  }


  // loadTourList() {
  //   this._DataProvider.getExhibitionByLangAndTourFB(this._DataProvider.Muzeum.id, this.lang, this.tour).subscribe(res => {
  //     this.TourList = res;
  //   });
  // }

  // clickOnBtnEnter() {
  //   console.log(this._DataProvider.selectedSpotExe);
  //   this.dismiss();
  //   this._DataProvider.numpadOpen = false;
  //   this._DataProvider.setStatstic("go spot")

  //   this._DataProvider.setLocalStorage('selectedSpotID', this._DataProvider.selectedSpotExe);
  //   this._DataProvider.player_state = 'open';
  //   this._DataProvider.current_spot.next({ selectedSpotID: this._DataProvider.selectedSpot._id })

  //   // this._DataProvider.navToSpot.next({
  //   //   muzeum:this.urlCode,
  //   //   id:this._DataProvider.selectedSpot._id,
  //   //   exhibition:this.id,
  //   // })


  //   // console.log(this._DataProvider.selectedSpotExe);
  //   // this.navCtrl.push(SpotPage, {
  //   //   muzeum:this.urlCode,
  //   //   id:this._DataProvider.selectedSpot._id,
  //   //   exhibition:this.id,

  //   // })

  // }


  input_code(num) {
    console.log(num);
    console.log(this.spotCode);
    
    
    if (this.spotCode == '···' && num != 'delete') {
      this.spotCode = num.toString();
    } else {
      if (num == 'delete') {
        if (this.spotCode != '···') {
          this.correctspot = null;
          this.spotCode = this.spotCode.slice(0, -1);
          if (!this.spotCode) this.spotCode = '···';
        }
      } else {
        if (this.spotCode.length < 3) {
          this.spotCode = this.spotCode.concat(num.toString());
        }
      }
    }

    if (this.spotCode.length == 3 && this.spotCode != '···') {
      console.log('spotCode', this.spotCode);
      this.getSpotData();
    }
  }

  getSpotData() {
    let self = this;
    this._DataProvider.getSpotByExebitionCodeAndLangFB(this._DataProvider.Muzeum.id, this.spotCode, this._DataProvider.lang.getValue())
    .subscribe(res => {
      console.log('result FB', res);
      this.result = res;

      console.log(this.result);
      if (this.result.length != 0) {
        if (this.result.length > 1) {

          this.result.forEach(element => {
            console.log("spot --->", element);
            console.log("tour list  console.log(this.TourList);   ", this.TourList)
            var x = this.TourList.find(x => x.id == element.ExhibitionsID)

            if (x) {
              console.log("xxx======================>", x)
              console.log("element======================>", element)
              this.correctspot = element;
            }
          });

          if (!this.correctspot) {
            this.correctspot = this.result[0];
          }


        } else {
          this.correctspot = this.result[0];
        }

        if (this.correctspot["notdisplayspot"] == true) {
          this.correctspot = null;
          return;
        }

        this._DataProvider.ExhibitionFBcol.doc(this.correctspot['ExhibitionsID']).ref.get().then(function (doc) {
          if (doc.exists) {
            console.log("Document data:", doc.data());
            self.exhibition = doc.data();

            // self.bgImg = self._DataProvider.Muzeum.background;
            self.exhibitionNname = self.exhibition.name;
            self.btnEnter = self.exhibition.btnEnter;
            self.exhibitionColor = self.exhibition.choosenColor;
            self.nosound = self.exhibition.nosound;

          } else {
            console.log("No such document!");
          }

        }).catch(function (error) {
          console.log("Error getting document:", error);
        });






        this.bgImg = this.correctspot["bgImg"];
        this.spotName = this.correctspot["name"];
        this.btnEnterShow = true;
        // this._DataProvider.selectedSpotExe = this.correctspot["id"];
        this._DataProvider.selectedSpot = this.correctspot
      } else {
        //do the not found here
        console.log("could not get !!");
      }



      //  getSpotByExebitionCodeAndLangFB.unsubscribe();


    });
  }

  // clickNum(numClicked) {
  //   var self = this;
  //   console.log('numClicked', numClicked);
  //   if (numClicked != 'delete') {
  //     // is a number
  //     console.log('digitsIndex', this.digitsIndex);
  //     if (this.digitsIndex == 2) {
  //       console.log('ask for data');
  //       this.digits[this.digitsIndex] = numClicked;
  //       this.digitsIndex++;

  //       //asking for data

  //       this._DataProvider.getSpotByExebitionCodeAndLangFB(this._DataProvider.Muzeum.id, this.spotCode, this._DataProvider.lang.getValue())
  //         .subscribe(res => {
  //           console.log('result FB', res);
  //           this.result = res;

  //           console.log(this.result);
  //           if (this.result.length != 0) {

  //             if (this.result.length > 1) {

  //               this.result.forEach(element => {
  //                 console.log("spot --->", element);
  //                 console.log("tour list  console.log(this.TourList);   ", this.TourList)
  //                 var x = this.TourList.find(x => x.id == element.ExhibitionsID)


  //                 if (x) {
  //                   console.log("xxx======================>", x)
  //                   console.log("element======================>", element)



  //                   this.correctspot = element;
  //                 }
  //               });

  //               if (!this.correctspot) {
  //                 this.correctspot = this.result[0];

  //               }


  //             } else {
  //               this.correctspot = this.result[0];
  //             }

  //             if (this.correctspot["notdisplayspot"] == true) {
  //               return;
  //             }

  //             this._DataProvider.ExhibitionFBcol.doc(this.correctspot['ExhibitionsID']).ref.get().then(function (doc) {
  //               if (doc.exists) {
  //                 console.log("Document data:", doc.data());
  //                 self.exhibition = doc.data();

  //                 // self.bgImg = self._DataProvider.Muzeum.background;
  //                 self.exhibitionNname = self.exhibition.name;
  //                 self.btnEnter = self.exhibition.btnEnter;
  //                 self.exhibitionColor = self.exhibition.choosenColor;
  //                 self.nosound = self.exhibition.nosound;

  //               } else {
  //                 console.log("No such document!");
  //               }

  //             }).catch(function (error) {
  //               console.log("Error getting document:", error);
  //             });






  //             this.bgImg = this.correctspot["bgImg"];
  //             this.spotName = this.correctspot["name"];
  //             this.btnEnterShow = true;
  //             this._DataProvider.selectedSpotExe = this.correctspot["id"];
  //             this._DataProvider.selectedSpot = this.correctspot
  //           } else {
  //             //do the not found here
  //             console.log("could not get !!");
  //           }



  //           //  getSpotByExebitionCodeAndLangFB.unsubscribe();


  //         });







  //     } else if (this.digitsIndex > 2) {
  //       console.log('no more -- light up the delete animation');
  //     } else {
  //       this.digits[this.digitsIndex] = numClicked;
  //       this.digitsIndex++;
  //     }//end of check index
  //   } else {
  //     // is delete
  //     this.btnEnterShow = false;
  //     console.log('digitsIndex', this.digitsIndex);
  //     if (this.bgImg != this._DataProvider.Muzeum.background) {
  //       console.log('this.bgImg !=');
  //       this.spotName = "";
  //       this._DataProvider.selectedSpot = { "id": "", _id: "", };
  //       this.btnEnterShow = false;
  //       this.bgImg = this._DataProvider.Muzeum.background;
  //     }


  //     if (this.digitsIndex != 0) {
  //       this.digitsIndex--;
  //       this.digits[this.digitsIndex] = "·";
  //     }


  //   }
  // }

}
