import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { ExhibitionPage } from './pages/exhibition/exhibition.page';
import { SpotPage } from './pages/spot/spot.page';
import { DataService } from './providers/data.service';
import { env } from 'src/app/app.module';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  rootPage: any;
  mdoalvoaopen = false;
  homePage = "home";
  lang;
  // nameName = "";
  // phoneName = "";
  muzeum;
  sendContactFLAG = false;

  constructor(
    public _http: HttpClient,
    public menuCtrl: MenuController,
    platform: Platform,
    public _DataProvider: DataService,
    private _ModalController: ModalController,
    private router: Router,
    private route: ActivatedRoute
  ) {
    platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      var userwork = this._DataProvider.getLocalStorage('userwork');
      if (!userwork) {
        this._DataProvider.setLocalStorage("userwork", '_' + Math.random().toString(36).substr(2, 9))
      }

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          console.log('event', event.url)
          this._DataProvider.previousUrl = this._DataProvider.currentUrl;
          this._DataProvider.currentUrl = event.url;

    if(env.projectId == 'voa-anu' && event.url=='/'){
       this.router.navigate(['/home/anu'], { replaceUrl: true })
    }


          // if (event.url == '/tabs') {
          //   this.router.navigate(['/tabs/home'], { replaceUrl: true })
          // }
          // console.log('PREV - ', this._DataProvider.previousUrl);
          // console.log('CURRENT - ', this._DataProvider.currentUrl);

        }
      });

      // this._DataProvider.lang = this._DataProvider.getLocalStorage('lang');
      // var urlCode = this._DataProvider.getLocalStorage('muzeum');
      // if (urlCode == 'onu') {
      //   urlCode = 'anu';
      //   this._DataProvider.setLocalStorage('muzeum', 'anu');
      // }

      // var urlCode2 = this.navparams.get('urlCode');

      //alinalin
      // if (urlCode) {
      //   this._DataProvider.getMuzeumByurlCodeFB(urlCode).subscribe((res: any) => {
      //     if (res.length != 0) {
      //       this._DataProvider.Muzeum = res[0];
      //       this._DataProvider.setLangObj();
      //     }
      //   });
      // }

      setTimeout(() => {
        this._DataProvider.loader = false;
      }, 2000)
      // statusBar.styleDefault();
      // splashScreen.hide();
    });
  }




  // check_Page_Numpad() {
  //   // console.log('currentPage',this._DataProvider.currentPage)
  //   if (
  //     this._DataProvider.currentPage != 'app'
  //     &&
  //     this._DataProvider.currentPage != 'home'
  //     &&
  //     this._DataProvider.currentPage != ''
  //     &&
  //     this._DataProvider.currentPage != 'welcome'
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }



  showNumPad() {
    // const modal = this._ModalController.create(ExhibitionPage);
    // modal.present();
    // this._DataProvider.openModal(ExhibitionPage);
    this._DataProvider.numpadOpen = true;
  }


  async show_new_sale_modal() {

  }



  ngOnInit() {
    // console.log(this._DataProvider.Muzeum.code);
    this._DataProvider.currentPage = 'app';

    // this.route.paramMap.subscribe((params: any) => {
    //   console.log(params);
    //   // this._DataProvider.params = params.params;
    //   // this._DataProvider.getData();
    //   if (params.params.muzeum && this._DataProvider.params.muzeum != params.params.muzeum) {
    //     this._DataProvider.getMuzeumByurlCodeFB(params.params.muzeum);
    //     this._DataProvider.params.muzeum = params.params.muzeum;
    //   }
    // });
    // this._DataProvider.fbTest('val').subscribe((res:any) => {
    //   console.log("res",res);
    // });
    // Handle route change


    // let onetime = true;
    // this._DataProvider.navToSpot.subscribe(item => {
    //   if (item.id != '' && this._DataProvider.currentPage != 'home') {

    //     console.log('navToSpot', item)

    //     console.log(item);
    //     this._DataProvider.selectedSpotExe = item.id;
    //     console.log(this._DataProvider.selectedSpotExe);
    //     console.log(this.muzeum);
    //     this._DataProvider.setLocalStorage('selectedSpotID', item.id);

    //     // this.router.navigate(['spot'], { replaceUrl: true });
    console.log('alinalin');

    //     // alinalin
    //     // this.navCtrl.push(SpotPage, item)
    //     // onetime = false; 

    //     // navToSpot.unsubscribe();
    //     this._DataProvider.navToSpot.next({ id: '', muzeum: '', exhibition: '' })
    //   }
    // })


    // if (this._DataProvider.ExhibitionsService) {

    // } else {
    //   //    this._DataProvider.ExhibitionsService = this._DataProvider.getLocalStorage('muzeum');
    // }

    console.log("this._DataProvider.Muzeum", this._DataProvider.ExhibitionsService);
  }
  // enterExhibition(select) {
  //   console.log(select, this._DataProvider.Muzeum.urlCode, select._id, "select");
  //   this._DataProvider.setLocalStorage('exhibitionID', select._id);

  //   this._DataProvider.selectedExebition = select;
  //   console.log('alinalin');

  //   // alinalin
  //   // this.navCtrl.push(ExhibitionPage, {
  //   //   muzeum: this._DataProvider.Muzeum.urlCode,
  //   //   id: select._id,
  //   // })

  // }


  // modalVoa() {
  //   this.menuCtrl.close();

  //   this._DataProvider.mdoalvoaopen = true;

  // }
  //  modalMuz(){
  //   this.menuCtrl.close();

  //      this._DataProvider.mdoalmuzopen=true;

  //    }

  // modalMuz() {
  //   this.menuCtrl.close();
  //   this._DataProvider.mdoalmuzopen = true;
  // }

  //    closeModal(){
  //      console.log("closemodal")
  //      this._DataProvider.mdoalmuzopen=false;
  //      this._DataProvider.mdoalvoaopen=false;
  //    }
  //    sendData(){

  //     // const url = 'https://api.irail.be/';

  // }



  // closeModal() {
  //   console.log("closemodal")
  //   this._DataProvider.mdoalmuzopen = false;
  //   this._DataProvider.mdoalvoaopen = false;
  // }
  // sendData() {

  //   // const url = 'https://api.irail.be/';

  //   this._http.post('https://webc.co.il/voaMail/', { name: this.nameName, phone: this.phoneName }).subscribe(respone => {
  //     console.log('respone: ', respone);
  //     this.sendContactFLAG = true;
  //   });

  // }

  // goToHome(params) {
  //   this._DataProvider.setLocalStorage('lang', '');



  //   if (!params) params = {};
  //   this._DataProvider.changeState('close')
  //   console.log('alinalin');

  //   // alinalin
  //   // this.navCtrl.setRoot(HomePage, {
  //   //   muzeum: this._DataProvider.Muzeum.urlCode,
  //   // });
  // }
  goToMuzeum(params) {
    if (!params) params = {};
    console.log('alinalin');

    // alinalin
    // this.navCtrl.setRoot(MuzeumPage);
  }


  // goToTourHomePage() {
  //   this._DataProvider.loader = true;
  //   this._DataProvider.getTours(this._DataProvider.Muzeum.id, this._DataProvider.lang.getValue()).subscribe(res => {

  //     if (res.length == 0 || this._DataProvider.currentPage == 'home') {
  //       this._DataProvider.hide_back = true;
  //       this._DataProvider.setLocalStorage('lang', '');
  //     }
  //     this._DataProvider.changeState('close');
  //     console.log('alinalin');
  //     // alinalin
  //     // this.navCtrl.setRoot(HomePage, {
  //     //   muzeum: this._DataProvider.Muzeum.urlCode,
  //     // });
  //   })




  // }


  goToMuz() {
    if (this._DataProvider.player_state != 'close') this._DataProvider.player_state = 'min';

    // console.log('lang1111111', this._DataProvider.NowLang, MuzeumPage);
    console.log('alinalin');
    // alinalin
    // this.navCtrl.setRoot(MuzeumPage, {
    //   muzeum: this._DataProvider.Muzeum.urlCode,
    // })

  }
}
