import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/providers/data.service';

@Component({
  selector: 'app-muzeum',
  templateUrl: './muzeum.page.html',
  styleUrls: ['./muzeum.page.scss'],
})
export class MuzeumPage implements OnDestroy {

  // lang;
  showBack = true;
  // muzeum;
  // MuzeumData = {
  //   name: '',
  //   discription: '',
  //   ExhibitionStyle: ''
  // };
  backGroundCove = "";

  id;
  urlCode;
  tour;
  exhibition;
  spots: any;
  oneExID: any;
  Exhibitions;
  multiExe = false;
  loaderPage = false;
  loaderPageDisplayNone = false;
  oneExe = false;
  params;
  cssEX: any = '';

  langSub;
  happen = false;
  current_tour = null; // current
  Tour_data: any = {
    tourlogo: null
  }

  // public navCtrl: NavController, public navParams: NavParams
  constructor(private sanitizer: DomSanitizer, public _DataProvider: DataService, private route: ActivatedRoute, private router: Router) {

    console.log("alinalin");
    // alinalin

    // if (this.navParams.get('muzeum') && !this._DataProvider.getLocalStorage('muzeum')) {
    //   this._DataProvider.setLocalStorage('muzeum', this.navParams.get('muzeum'));
    // } else {
    //   if (!this.navParams.get('muzeum') && !this._DataProvider.getLocalStorage('muzeum')) {
    //     this.navCtrl.setRoot("Welcome")
    //   }
    // }
    this.route.paramMap.subscribe((params: any) => {
      console.log(params);
      this.params = params.params;
      this._DataProvider.params = this.params;
    });


    // this.lang = this._DataProvider.getLocalStorage('lang') ? 'he' : 'he';
    // this.muzeum = this._DataProvider.getLocalStorage('muzeum');
    // console.log('this._DataProvider.getLocalStorage(lang)', this._DataProvider.getLocalStorage('lang'));
    // console.log('this._DataProvider.getLocalStorage(muzeum)', this._DataProvider.getLocalStorage('muzeum'));

    // this._DataProvider.navigationC.home = true;
    // this._DataProvider.navigationC.more = false;
    // this._DataProvider.navigationC.numType = false;


  }

  initPage() {
    if (!this._DataProvider.Muzeum.id || this._DataProvider.Muzeum.urlCode != this.params.muzeum) {
      this._DataProvider.getMuzeumByurlCodeFB(this.params.muzeum).subscribe((res: any) => {
        console.log("TopMenuComponent---res", res);
        if (res.length) {
          this._DataProvider.Muzeum = res[0];
          console.log("MUZEUM - SETLANGOBJ");
          let lang_obj = this._DataProvider.Muzeum.lang.find(lang => lang.code == this._DataProvider.lang.getValue());
          if (lang_obj) {
            this._DataProvider.setLangObj();
            this.getData();
          } else {
            this._DataProvider.lang.next('');
          }
        }
        // if (lang && muzeum) {
        // }
        else {
          this._DataProvider.loader = false;
        }
      });
    } else {
      this.getData();
    }
  }

  ionViewDidEnter() {

    if (!this._DataProvider.lang.getValue()) {
      this.langSub = this._DataProvider.lang.subscribe(val => {
        console.log(val);
        if (val) {
          this.initPage();
        }
      });
    } else {
      this.initPage();
    }




    // if (this._DataProvider.lang.getValue()) {
    //   this.initPage();
    // }
    // this.langSub = this._DataProvider.lang.subscribe(val => {
    //   console.log(val);
    //   if (val) {
    //     this.initPage();
    //   }
    // });
    // this.current_tour = localStorage.getItem('TourName');
    // this.tour = this._DataProvider.getLocalStorage('Tour');

    // if (this.params.tour) {

    // }

    this._DataProvider.currentPage = 'muzeum';


    // this._DataProvider.showPad = false;
    // this._DataProvider.menuShow = false;
    // this._DataProvider.mapShow = false;
    // this._DataProvider.NowLang = this.lang;
    // this.muzeum = this._DataProvider.getLocalStorage('muzeum');
    // console.log(this.muzeum);
    // if (!this.happen) {
    // this.happen = true;



    // if (this.muzeum) {
    // console.log('MUZMUZ', this.muzeum);

    // });
    // }



    // this._DataProvider.getMuzeumByurlCode(this.muzeum)
    // .subscribe(res => {
    //   console.log(res["theResult"][0]);
    //   this._DataProvider.Muzeum = res["theResult"][0];

    //   this.MuzeumData = this._DataProvider.getMuzeumData(this.lang);

    //   this._DataProvider.getExhibitionByLang(this._DataProvider.Muzeum._id,this.lang)
    //   .subscribe(res => {
    //     console.log(res["theResult"]);
    //     this.Exhibitions = res["theResult"];
    //     this._DataProvider.ExhibitionsService= res["theResult"];
    // console.log(this.Exhibitions);
    // console.log(this.MuzeumData);
    // console.log(this.muzeum);
    // console.log(this.lang);

    //       });
    //     });








    // }

    // Put here the code you want to execute
  }
  parseIntProperty(array, property) {
    for (let i = 0; i < array.length; i++) {
      array[i][property] = parseInt(array[i][property]);
    }
    return array;
  }

  get_data_with_tour() {
    console.log('this.Tour_data', this.Tour_data)
    this._DataProvider.setStatstic("Tour Select")

    this._DataProvider.getExhibitionByLangAndTourFB(this._DataProvider.Muzeum.id, this.Tour_data.lang, this.Tour_data.id)
      .subscribe(res => {
        console.log(res);
        if (!res || !res.length) return;
        this.Exhibitions = res;
        this._DataProvider.ExhibitionsService = res;

        if (this._DataProvider.Muzeum.settings.type == 'oneExe') {
          this.Exhibitions = res;
          this.oneExe = true;
          // get spost
          let self = this;
          // console.log('this.id--:',this.id);
          this.oneExID = res[0].id;
          console.log('this.oneExID--:', this.oneExID);

          this._DataProvider.getSpotsByExhibitionIDFB(this.oneExID)
            .subscribe(res => {
              console.log(res);
              self.spots = res;
              // self._DataProvider.spotsList = res;
              self.loaderPage = true;
              setTimeout(() => {
                self.loaderPageDisplayNone = true;
              }, 1300);





              console.log(self.spots);
            });
        } else if (this._DataProvider.Muzeum.settings.type == 'multiExe') {
          if (res.length > 1) {
            res = res.sort((a, b) => a["order"] - b["order"]);
          }

          this.Exhibitions = res;
          this.Exhibitions.sort((a, b) => (a.order < b.order ? -1 : 1));
          this.multiExe = true;
          this.Exhibitions.forEach((element, index) => {
            console.log('elementelementelement', element);

            this._DataProvider.getSpotsByExhibitionIDFB(element.id)
              .subscribe(res => {
                res = this.parseIntProperty(res, 'order');
                var res2: any = res;
                if (res2.length > 1) {
                  res2.sort((a, b) => (a.order < b.order ? -1 : 1));
                }

                this.Exhibitions[index]['spots'] = res2;
                console.log('elem- ' + element.id, res2);
                console.log("this.Exhibitions[index][spots]",this.Exhibitions[index]['spots'])

              });
          });

          // get spost
          let self = this;
          // console.log('this.id--:',this.id);
          console.log(res);
          this.oneExID = res[0].id;
          console.log('this.oneExID--:', this.oneExID);

          this._DataProvider.getSpotsByExhibitionIDFB(this.oneExID)
            .subscribe((res: any) => {
              console.log(res);
              if (res.length > 1) {
                res.sort((a, b) => (a.order < b.order ? -1 : 1));
              }
              self.spots = res;
              // self._DataProvider.spotsList = res;
              self.loaderPage = true;
              setTimeout(() => {
                self.loaderPageDisplayNone = true;
              }, 1300);
              console.log(self.spots);

            });
        }
        this.loaderPage = true;
        setTimeout(() => {
          this.loaderPageDisplayNone = true;
        }, 1300);

        console.log(this.Exhibitions);
      });
  }



  sub_tour:any;
  GoToTour(item) {
    // setTimeout(() => {
    console.log(item);

    // this._DataProvider.setLocalStorage('Tour', item.id);
    // this._DataProvider.setLocalStorage('TourName', item.name);
    console.log('alinalin');
    this.router.navigate(['museum/' + this.params.muzeum + '/' + item.id], { replaceUrl: true });
    // alinalin
    // this.navCtrl.push(MuzeumPage, {
    //   muzeum: this._DataProvider.Muzeum.urlCode
    // })


    this._DataProvider.setStatstic("Tour Select")

    // }, 200);
  }

  getData() {


    // if (this.params.spot) {
    //   this._DataProvider.getSpotByExebitionCodeAndLangFB(this._DataProvider.Muzeum.id, this._DataProvider.params.spot, this._DataProvider.lang.getValue()).subscribe(res => {
    //     console.log(res);
    //     if (res[0]) {
    //       this._DataProvider.gotoSpot(res[0]);
    //     }
    //   });
    // }

    // this._DataProvider.getMuzeumByurlCodeFB(this.muzeum).subscribe((res: any) => {
    // console.log("res", res);
    // console.log(res[0]);
    // this._DataProvider.Muzeum = res[0];
    // this._DataProvider.setLangObj();
    // this._DataProvider.backGroundCove = res[0].background;
    // this.backGroundCove = res[0].background;
    this.sub_tour = null;


    if (this._DataProvider.Muzeum.settings.css) {
      this.cssEX = ` <style>`;
      this.cssEX += this._DataProvider.Muzeum.settings.css;
      this.cssEX += ` </style>`;
      this.cssEX = this.sanitizer.bypassSecurityTrustHtml(this.cssEX);
      console.log('CSS--------', this.cssEX);
    }

    console.log('FB - Muzeum._id', this._DataProvider.Muzeum);
    console.log('FB - this.lang', this._DataProvider.lang.getValue());

    let langg = this._DataProvider.lang.getValue();
    let exist_in_m = this._DataProvider.Muzeum.lang.filter(lang => lang.code == langg);
    console.log('exist_in_m', exist_in_m);
    if (exist_in_m.length == 0) {
      localStorage.setItem('lang', '');
      return;
    }



    if (this.params.tour) {
      this._DataProvider.getTourById(this.params.tour).subscribe((tour_res: any) => {
        let tour = tour_res.data();
        console.log("tour  res: ---", tour_res.data());
      
        
        if (tour) {
          tour.id = this.params.tour;
          this.Tour_data = tour;
          this._DataProvider.Tour_data = tour;
          if(tour.sub_tour){
            this._DataProvider.getTourById(tour.sub_tour).subscribe((subtour_res: any) => {
              this.sub_tour = subtour_res.data();
              console.log("subtour res: ---",this.sub_tour);

            });
          }
          this.get_data_with_tour();
        } else {
          this._DataProvider.getTourByUrlcode(this.params.tour).subscribe((tour_res2: any) => {
            console.log("tour res2: ---", tour_res2);
            if (tour_res2[0]) {
              this.Tour_data = tour_res2[0];
              this._DataProvider.Tour_data = tour_res2[0];
              this.get_data_with_tour();
            }
          });
        }

      });


    } else {
      this._DataProvider.getExhibitionByLangFB(this._DataProvider.Muzeum.id, this._DataProvider.lang.getValue())
        .subscribe(res => {
          console.log(res);
          if (res.length > 1) {
            res = res.sort((a, b) => a["order"] - b["order"]);
          }
          this.Exhibitions = res;
          this._DataProvider.ExhibitionsService = res;
          if (this._DataProvider.Muzeum.settings.type == 'oneExe') {
            this.Exhibitions = res;
            this.oneExe = true;


            // get spost
            let self = this;
            // console.log('this.id--:',this.id);
            this.oneExID = res[0].id;
            console.log('this.oneExID--:', this.oneExID);

            this._DataProvider.getSpotsByExhibitionIDFB(this.oneExID)
              .subscribe(res => {
                console.log(res);
                if (res.length > 1) {
                  res = res.sort((a, b) => a["order"] - b["order"]);
                }
                self.spots = res;
                // self._DataProvider.spotsList = res;
                self.loaderPage = true;
                setTimeout(() => {
                  self.loaderPageDisplayNone = true;
                }, 1300);
                console.log(self.spots);

              });
          } else if (this._DataProvider.Muzeum.settings.type == 'multiExe') {
            // if (res.length > 1) {
            //   res = res.sort((a, b) => a["order"] - b["order"]);
            // }

            // this.Exhibitions = res;
            // this.multiExe = true;
            // // get spost
            // let self = this;
            // // console.log('this.id--:',this.id);
            // this.oneExID = res[0].id;
            // console.log('this.oneExID--:', this.oneExID);

            // this._DataProvider.getSpotsByExhibitionIDFB(this.oneExID)
            //   .subscribe(res => {
            //     console.log(res);
            //     self.spots = res;

            //     // self._DataProvider.spotsList = res;
            //     self.loaderPage = true;
            //     setTimeout(() => {
            //       self.loaderPageDisplayNone = true;
            //     }, 1300);
            //     console.log(self.spots);

            //   });
            if (res.length > 1) {
              res = res.sort((a, b) => a["order"] - b["order"]);
            }

            this.Exhibitions = res;
            this.Exhibitions.sort((a, b) => (a.order < b.order ? -1 : 1));
            this.multiExe = true;
            this.Exhibitions.forEach((element, index) => {
              console.log('elementelementelement', element);

              this._DataProvider.getSpotsByExhibitionIDFB(element.id)
                .subscribe(res => {
                  res = this.parseIntProperty(res, 'order');
                  var res2: any = res;

                  if (res2.length > 1) {
                    res2.sort((a, b) => (a.order < b.order ? -1 : 1));
                  }

                  this.Exhibitions[index]['spots'] = res2;
                  console.log('elem- ' + element.id, res2);
                  console.log("this.Exhibitions[index][spots]",this.Exhibitions[index]['spots'])
                });
            });

            // get spost
            let self = this;
            // console.log('this.id--:',this.id);
            console.log(res);
            this.oneExID = res[0].id;
            console.log('this.oneExID--:', this.oneExID);

            this._DataProvider.getSpotsByExhibitionIDFB(this.oneExID)
              .subscribe((res: any) => {
                console.log(res);
                res = this.parseIntProperty(res, 'order')
                if (res.length > 1) {
                  res.sort((a, b) => (a.order < b.order ? -1 : 1));
                }
                self.spots = res;
                // self._DataProvider.spotsList = res;
                self.loaderPage = true;
                setTimeout(() => {
                  self.loaderPageDisplayNone = true;
                }, 1300);
                console.log(self.spots);

              });
          }
          this.loaderPage = true;
          setTimeout(() => {
            this.loaderPageDisplayNone = true;
          }, 1300);

          console.log(this.Exhibitions);
        });
    }
  }

  goToExlist(exid) {


    console.log('this.Exhibitions[0]', exid);
    // console.log('this.muzeum', this.muzeum);
    // this._DataProvider.selectedExebition = exid;
    // this._DataProvider.setLocalStorage('exhibitionID', exid);
    this.router.navigate(['exhibition/' + this.params.muzeum + '/' + exid], { replaceUrl: true });

    this._DataProvider.setStatstic("Exhibition Select")

    console.log("alinalin");
    // alinalin

    // this.navCtrl.push(ExhibitionListPage, {
    //   muzeum: this.muzeum,
    //   exid: exid
    // })
  }


  // gotoSpot(item){
  //   console.log(item);
  //   this._DataProvider.selectedSpotExe = item.id;
  //   console.log(this._DataProvider.selectedSpotExe);
  //   console.log(this.muzeum);
  //   this._DataProvider.setLocalStorage('selectedSpotID',this._DataProvider.selectedSpotExe);
  //   this.navCtrl.push(SpotPage, {
  //     muzeum:this.muzeum
  //     // id:this._DataProvider.selectedSpot._id,
  //     // exhibition:this.id,

  //   })

  // }
  // gotoSpot(item) {
  //   console.log(item);
  //   this._DataProvider.selectedSpotExe = item.id;
  //   this._DataProvider.player_state = 'open';
  //   // this._DataProvider.current_spot.next({ selectedSpotID: item.id })
  //   console.log(this._DataProvider.selectedSpotExe);
  //   // console.log(this.muzeum);

  //   // alinalin
  //   // this._DataProvider.setLocalStorage('selectedSpotID', item.id);
  //   // let el = document.getElementById('page5');
  //   // el.classList.remove('slideout');

  //   // this.navCtrl.push(SpotPage, {
  //   //   muzeum:this.muzeum,
  //   //   id:this._DataProvider.selectedSpotExe,
  //   //   exhibition:item.ExhibitionsID,

  //   // })

  // }




  // enterSpotsList(select) {
  //   this._DataProvider.selectedExebition = select;
  //   this._DataProvider.setLocalStorage('exhibitionID', select.id);
  //   this._DataProvider.setLocalStorage('spotType', select.spotType);
  //   console.log("alinalin");
  //   // alinalin
  //   // this.navCtrl.push(SpotsListPage, {
  //   //   muzeum: this.muzeum,
  //   // })

  // }

  // enterExhibitionGlobal() {
  //   console.log('this.Exhibitions[0]', this.Exhibitions[0]);
  //   // console.log('this.muzeum', this.muzeum);
  //   this._DataProvider.selectedExebition = this.Exhibitions[0];
  //   this._DataProvider.setLocalStorage('exhibitionID', this.Exhibitions[0].id);

  //   console.log("alinalin");
  //   // alinalin
  //   // this.navCtrl.push(ExhibitionPage, {
  //   //   muzeum: this.muzeum,
  //   // })
  // }


  // goToCode() {



  //   this._DataProvider.selectedExebition = this.Exhibitions[0];
  //   this._DataProvider.setLocalStorage('exhibitionID', this.Exhibitions[0].id);
  //   this._DataProvider.setLocalStorage('spotType', this.Exhibitions[0].spotType);

  //   console.log("alinalin");
  //   // alinalin
  //   // this.navCtrl.push(ExhibitionPage, {
  //   //   muzeum: this.muzeum,
  //   // })
  // }

  // enterExhibition(select) {
  //   console.log(select);
  //   this._DataProvider.selectedExebition = select;
  //   this._DataProvider.setLocalStorage('exhibitionID', select.id);
  //   this._DataProvider.setLocalStorage('spotType', select.spotType);

  //   console.log("alinalin");
  //   // alinalin
  //   // this.navCtrl.push(ExhibitionPage, {
  //   //   muzeum: this.muzeum,
  //   // })

  // }

  // goBack() {
  //   setTimeout(() => {
  //     console.log("alinalin");
  //     // alinalin
  //     // this.navCtrl.setRoot(MuzeumPage, {
  //     //   muzeum: this.muzeum,
  //     // })
  //   }, 200);
  // }

  // ulClass() {
  //   if (this._DataProvider.Muzeum.ExhibitionStyle == 'option2') {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  ngOnDestroy(): void {
    if (this.langSub) {
      this.langSub.unsubscribe();
    }
  }

}
