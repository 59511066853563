import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/providers/data.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent {

  text: string;
  muzeumLogo: string = "assets/imgs/logoDEF.png";
  voaLogo = "assets/imgs/logovoa.png";
  // urlCode: String;
  muzeumid: any;
  constructor(
    public _DataProvider: DataService,

    //  public navCtrl: NavController,
    //   public navParams:NavParams,
  ) {
    // console.log('top-muzeum:',this.navParams.get('muzeum'));

    // if(this.navParams.get('muzeum')){
    console.log('not bug');
    // this.urlCode = this.navParams.get('muzeum');
    // }else{
    console.log(' buge321#!@#!@!#!@#!');
    // this.urlCode = this._DataProvider.getLocalStorage('muzeum');

    // }
    // alinalin
    // if (this.urlCode) {


    //   this._DataProvider.getMuzeumByurlCodeFB(this.urlCode).subscribe((res: any) => {
    //     console.log("TopMenuComponent---res", res);
    //     console.log("TopMenuComponent---res", res.length);
    //     if (res.length != 0) {
    //       this._DataProvider.Muzeum = res[0];
    //       this._DataProvider.setLangObj();
    //       this.muzeumLogo = this._DataProvider.Muzeum.logo;
    //       this.muzeumid = this._DataProvider.Muzeum.id;
    //     }

    //   });
    // } else {

    // }




  }


  showBackButton() {

    if (
      this._DataProvider.currentPage != 'app'
      &&
      this._DataProvider.currentPage != 'home'
      &&
      this._DataProvider.currentPage != ''
      &&
      this._DataProvider.currentPage != 'welcome'
      &&
      this._DataProvider.currentPage != 'muzeum'




    ) {
      return false;
    } else {
      return true;
    }








  }



  // moveToPage(pagetoMove){
  //   console.log('pagetoMove',pagetoMove);
  //  if(pagetoMove=='exe'){

  //   this.navCtrl.push(ExhibitionPage, {
  //     muzeum:this.muzeumid,
  //   })
  //  }


  // }

  moveBack() {
    window.history.back();
  }

}
